<template>
        <div class="contact-container">
            <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-3">
                    <h4 class="contact-label">{{ title }}</h4>
                </div>
                <div class="col-xs-12 col-sm-8 col-md-9">
                    <input :type="type"
                           height="40"
                           class="form-control"
                           :placeholder="placeholder"
                           ref="contactInput"
                           @input="updateInput">
                </div>
            </div>
        </div>
</template>

<script>

    export default {
        name: "contact-input",
        props: ["title", "placeholder", "type", "classes"],
        data() {
            return {

            }
        },
        methods: {
            updateInput() {
                this.$emit("input", this.$refs.contactInput.value);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .contact-container {
        margin-bottom: 20px;
        background-color: $mainOffsetColor;
        padding: 5px;
        border-radius: 3px;
        border: 1px solid darken($mainOffsetColor, 10%);
        height: 100%;

        .contact-label {
            float: left;
            margin-top: 14px;
            font-weight: bold;
        }

        input {
            margin-top: 5px;
        }
    }

</style>