import ContentType from "./ContactContentType";

//for issues 'placeholder' is an optional property that will override the default

export default [
    {
        name: "Issues with this Site",
        active: false,
        handleRequest: "sendEmail",
        file: {
            title: "Optional Screenshot",
            file: null,
            required: false
        },
        inputs: [],
        issues: [
            {
                active: false,
                message: "",
                type: ContentType.PI,
                description: "Downloading Product Spreadsheets"
            },
            {
                active: false,
                message: "",
                type: ContentType.PI,
                description: "Uploading Product Spreadsheets"
            },
            {
                active: false,
                message: "",
                type: ContentType.PI,
                description: "Updating Size Charts"
            },
            {
                active: false,
                message: "",
                type: ContentType.IMG,
                description: "Uploading Images"
            },
            {
                active: false,
                message: "",
                type: ContentType.VIDEO,
                description: "Uploading Videos"
            },
            {
                active: false,
                message: "",
                type: ContentType.LOGO,
                description: "Uploading Logos"
            }
        ]
    },
    {
        name: "REI.com Product Page Corrections",
        active: false,
        handleRequest: "sendEmail",
        file: null,
        inputs: [
            {
                title: "REI.com Product Page URL",
                placeholder: "https://www.rei.com/product/999999",
                message: "",
                required: true,
                type: "text"
            }
        ],
        issues: [
            {
                active: false,
                message: "",
                type: ContentType.IMG,
                description: "Imagery"
            },
            {
                active: false,
                message: "",
                type: ContentType.VIDEO,
                description: "Video"
            },
            {
                active: false,
                message: "",
                type: ContentType.IMG,
                description: "Other"
            }
        ]
    },
    {
        name: "Confirm Your Submission Has Been Received",
        active: false,
        handleRequest: "requestUploadList",
        file: null,
        inputs: [
            {
                title: "Uploads from",
                placeholder: "YYYY-MM-DD",
                message: "",
                required: true,
                type: "date"
            },
            {
                title: "to (30 day limit)",
                placeholder: "YYYY-MM-DD",
                message: "",
                required: true,
                type: "date"
            }
        ],
        issues: []
    },
    {
        name: "Size Chart Updates",
        active: false,
        handleRequest: "sendEmail",
        file: {
            title: "Optional Size Chart Image",
            file: null,
            required: false
        },
        inputs: [],
        issues: [
            {
                active: true,
                message: "",
                type: ContentType.PI,
                description: "Size Chart Updates",
                placeholder: "Please describe the size chart update in detail, and then click Send Email button above"
            }
        ]
    }
];