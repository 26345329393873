<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row">
                <div class="col-xs-12">
                    <h3 class="title">Upload Templates</h3>
                    <p class="text-info">Upload files to the site for vendors to access. Existing files are replaced.</p>
                </div>
            </div>

            <div class="row">
		        <div class="col-xs-10 col-xs-offset-1 template-container">
		
		            <div class="row">
		                <div class="col-xs-12 col-sm-6 col-md-5">
		                    <select v-model="selectedCategory" data-ui="category-dropdown" class="form-control">
	                            <option v-for="category in categories" v-bind:value="category">{{  formatCategory(category)  }}</option>
	                        </select>
		                </div>
		                <div class="col-xs-12 col-sm-6 col-md-7">
		                    <input type="file"
		                           height="40"
		                           data-ui="choose-template-button"
		                           @change="processFiles($event.target.files)"
		                           class="form-control template-file"
		                           multiple>
		                </div>
		            </div>
		        </div>
		    </div>

        </div>
    </div>

</template>

<script>

	import Util from "../../util/util";

  const EXT = "xlsx";

    export default {
        name: "upload-templates",
        data() {
            return {
            	selectedCategory: "",
            	categories: []
            }
        },
        methods: {
            processFiles(files) {
            	
            	if (!this.selectedCategory) {
                    this.addMessage("Please select a category");
                    return;
                }
                
                for (let i = 0; i < files.length; i++){
                	
                	let file = files[i];
                	
	                if (this.getExtension(file.name) !== EXT) {
	                    this.addMessage("Error: Only XLSX files can be uploaded");
	                    continue;
	                }
	
	                let formData = new FormData();
	                formData.append("file", file);
	
	                this.$http.post( `rs/template/upload/${this.selectedCategory}/${file.name}`, formData )
                      .then( response => {
                        this.addMessage( "Uploaded " + file.name );
                      } )
                      .catch( error => {
                        let message;
                        let status = error.status || error.response.status;

                        if ( status === 400 ) {
                          message = error.response.data;
                        } else {
                          //default error message
                          message = "Error: " + file.name + " upload failed";
                        }
                        this.addMessage( message );
                      } );
                }
                
            },
            getExtension(filename) {
                return filename.substring(filename.lastIndexOf(".", filename) + 1).toLowerCase();
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            },
            formatCategory(category) {
                return Util.removeUnderscore(category)
            }
        },
        created(){
            this.$http.get("/rs/template/category")
            .then(data => {

                this.data = data.data;
                this.categories = Object.keys(this.data);
            })
            .catch(error => {

                let message;

                if (error.response.status === 401) {
                    message = this.$store.state.unauthorized;
                } else {
                    message = "Error listing categories";
                }
                this.addMessage(message);
            });
       }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .admin-panel {
        min-height: 250px;
        height: 100%;
    }
    
    .template-container {
        margin-top: 20px;
        background-color: $mainOffsetColor;
        padding: 5px;
        border-radius: 3px;
        border: 1px solid darken($mainOffsetColor, 10%);
        height: 100%;

        .template-label {
            float: left;
            margin-top: 14px;
            font-weight: bold;
        }

        .template-file {
            float: left;
            width: 100%;

            &:hover {
                cursor: pointer;
            }
        }
    }

</style>