<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row download-controls">
                <div class="col-xs-5 col-sm-6">
                    <h3 class="title">PDF Documents</h3>
                    <p class="text-info">Click the upload button when ready to upload To Media Tool</p>
                </div>
            </div>

            <div class="row term-container">
                <div class="col-xs-10 col-sm-6">
                    <input type="text" v-model="filterTerm" class="form-control" placeholder="Filter by vendor name, email, brand, filename or style number"/>
                </div>
                <div class="col-xs-1" >
                    <img title="refresh" :class="{'reload-image': true, 'spin-image' : loadingAssets }" @click="fetchData" :src="reloadImg" width="22" height="17">
                </div>
            </div>

            <div class="row">

                <div v-if="loadingAssets" class="col-xs-12">
                    <h3>Loading...</h3>
                </div>

                <div v-else-if="!filtered.length" class="col-xs-12">
                    <h3>No Results</h3>
                </div>

                <div v-else class="col-xs-12 table-container">
                    <table class="table table-responsive">
                        <thead>
                        <tr>
                            <th>
                                <span class="icon-container" title="Upload All">
                                    <font-awesome-icon icon="chevron-circle-up" @click="uploadAll(filtered)"/>
                                </span>
                            </th>
                            <th>Upload Date</th>
                            <th>Brand</th>
                            <th>Filename - Click to Preview</th>
                            <th></th>
                            <th>Title</th>
                            <th>Email</th>
                            <th>Notes</th>
                            <th>Style</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="asset in filtered" :key="asset.id">
                            <td>
                                <span class="icon-container" title="Upload">
                                    <font-awesome-icon icon="chevron-circle-up" @click="upload(asset)"/>
                                </span>
                            </td>
                            <td class="download-date">{{ asset.uploadDto.uploadDate }}</td>
                            <td>{{ asset.uploadDto.brand }}</td>
                            <td class="download-file" @click="download(asset)">{{ asset.uploadDto.filename }}</td>
                            <td><img :class="{'reload-image': true, 'spin-image': asset.downloading, 'display-image': !asset.downloading }"
                                     :src="reloadImg" width="22" height="17">
                            </td>
                            <td>
                                <select class="form-control title-dropdown" v-model="asset.title">
                                    <option class="form-control">User Manual</option>
                                    <option class="form-control">Size Chart</option>
                                    <option class="form-control">Label</option>
                                </select>
                            </td>
                            <td><a :href="'mailto:' + asset.uploadDto.email">{{ asset.uploadDto.email }}</a></td>
                            <td>{{ asset.uploadDto.notes }}</td>
                            <td>{{ asset.uploadDto.styleId }}</td>
                            <td>
                                <span class="icon-container" title="Remove">
                                    <font-awesome-icon icon="trash-alt" @click="remove(asset)"/>
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    </div>

</template>

<script>

    import reloadImg from "../../../resources/static/img/reload.png";
    import formatDate from "../../util/formatDate";

    export default {
        name: "pdf-assets",
        data() {
            return {
                loadingAssets: false,
                pdfAssetList: [],
                filterTerm: "",
                reloadImg
            }
        },
        computed: {
            filtered() {
                let filterList = this.pdfAssetList;
                if (this.filterTerm) {
                    filterList = filterList.filter(asset => asset.uploadDto.filterTerm.includes(this.filterTerm.toLowerCase()));
                }
                return filterList.map((asset) => {
                    const uploadDto = {
                        ...asset.uploadDto,
                        uploadDate: formatDate(asset.uploadDto.uploadDate),
                    };
                    return {
                        ...asset,
                        uploadDto,
                    };
                });
            }
        },
        methods: {
            download(asset) {

                const fp = asset.filepath;

                this.$http({
                    url: `rs/asset/${asset.id}`,
                    method: "GET",
                    responseType: "blob"
                }).then(response => {
                    var file = new Blob([response.data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    var win = window.open();
                    win.document.write( '<iframe src="' + fileURL + '#toolbar=0" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>' )
                }).catch(error => {
                    this.addMessage(`Error downloading asset ${fp}`);
                });
            },
            remove(asset) {
                this.archive(asset);
                this.pdfAssetList.splice(this.pdfAssetList.indexOf(asset), 1);
            },
            uploadAll(assets) {
                assets.forEach(asset => {
                    this.upload(asset);
                });
            },
            upload(asset) {

                if (!asset.title) {
                    this.addMessage("Error: " + asset.uploadDto.filename + " title required");
                    return;
                }

                this.$http.post(`rs/media/upload/${asset.id}/${asset.title}/${asset.uploadDto.styleId}`)
                    .then(response => {
                        this.archive(asset);
                        this.pdfAssetList.splice(this.pdfAssetList.indexOf(asset), 1);
                    })
                    .catch(error => {
                        this.addMessage("Error: " + asset.uploadDto.filename + " upload failed");
                    });

            },
            archive(asset) {
                this.$http.delete(`rs/asset/${asset.id}`)
                    .then(response => {})
                    .catch(error => {});
            },
            initPaths(assets) {

                assets.forEach((asset) => {

                    const type = asset.filepath.substring(0, asset.filepath.lastIndexOf("/"))
                        .replace("asset/", "")
                        .toUpperCase();

                    asset.downloading = false;

                    if (type === "PDF") {
                        //set default title
                        asset.title = "User Manual";

                        this.pdfAssetList.push(asset);
                    }

                });

                //add the filter term
                this.pdfAssetList.forEach(asset => {
                    asset.uploadDto.filterTerm = `${asset.uploadDto.name}${asset.uploadDto.brand}${asset.uploadDto.filename}${asset.uploadDto.email}${asset.uploadDto.styleId}`.toLowerCase();
                });

            },
            fetchData() {

                this.loadingAssets = true;

                this.$http.get("rs/asset/data")
                    .then(response => {

                        if (response.data && response.data.length) {
                            this.pdfAssetList = [];
                            this.initPaths(response.data);
                        } else {
                            this.addMessage("No new assets");
                        }
                        this.loadingAssets = false;

                    }).catch(error => {

                    if (error.response.status === 401) {
                        //redundant auth check, non admin's shouldn't be able to get here
                        //but just in case redirect to login page
                        this.$router.push("/");
                    } else {
                        this.addMessage("Error fetching uploaded assets");
                        this.loadingAssets = false;
                    }
                });
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        },
        mounted() {
            this.fetchData();
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .table-container {
        height: auto;
        max-height: 800px;
        overflow: auto;

        table {
            display: block;

            thead {
                background-color: $mainOffsetColor;
                font-weight: bold;

                th {
                    height: 50px;
                }
            }

            tbody {
                background-color: white;
            }
        }

    }

    .term-container {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .download-file {
        color: $adminBlue;
            &:hover {
                 cursor: pointer;
                 text-decoration: underline;
             }
    }

    .icon-container {
        cursor: pointer;
    }

    .title-dropdown {
        width: auto;
    }

</style>