<template>
    
    <div class="row">
        <div class="col-sm-10 col-sm-offset-1 finder-content">

            <div class="row">
                <div class="col-xs-10 col-xs-offset-1">
                    <div class="title-main">Product Photography: Look Up REI 10-Character SKU</div>
                    <div class="title-sub">Don’t know your REI 10-character SKU? Simply enter your REI 6-character style below, multiples separated by commas or spaces.</div>
                </div>
            </div>
            <div class="row lookup-container">
                <div class="col-sm-4 col-xs-offset-1 col-xs-5">
                    <input class="form-control" id="lookup-text" v-model="search" @keyup.enter="onSubmit" type="text" data-ui="sku-field" placeholder="Enter one or more REI 6-character style">
                    <input id="lookup-btn" type="image" data-ui="lookup-button" @click="onSubmit" :src="btnImg">
                </div>
                <div class="col-sm-4 col-xs-5">
                    <div>
                        <div id="tip-title">Tip:</div>
                        <div id="tip-msg">The REI 10-character SKU associates an image with its product description and color. Add the 10-character SKU to the beginning of your filename.</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10 col-xs-offset-1" >
                    <table v-if="skus.length" class="table sku-table">
                        <thead>
                            <tr><th v-for="header in headers" :key="header">{{ header }}</th></tr>
                        </thead>
                        <tbody data-ui="product-data" id="productData">
                            <tr v-for="sku in skus" v-bind:key="sku.id">
                                <td>{{ sku.brand }}</td>
                                <td>{{ sku.style }}</td>
                                <td>{{ sku.id }}</td>
                                <td>{{ sku.color }}</td>
                                <td>{{ sku.casemark }}</td>
                                <td>{{ sku.desc }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-sm-offset-5 col-xs-4 col-xs-offset-4">
                    <input v-if="skus.length" @click="csvExport" data-ui="download-button" id="csvBtn" class="form-control download-btn" type="button" value="Download">
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

    import btnImg from "../../resources/static/img/search.gif";
    import saveFile from "../util/saveFile";

    export default {
        name: "sku-finder",
        data() {
            return {
                search: "",
                searchStyles: [],
                skus: [],
                httpCount: 0,
                httpComplete: 0,
                showSpinner: false,
                headers: ["Brand", "REI Style", "REI Sku", "Color", "Casemark", "Description"],
                btnImg
            }
        },
        computed: {
            unauthorized() {
                return this.$store.state.unauthorized;
            }
        },
        methods: {
            onSubmit() {
                
                this.skus = [];
                if (this.search.length){

                    this.showSpinner = true;
                    this.searchStyles = this.search
                        .replace(/,/g, " ").split(" ")
                        .filter(sku => sku.trim().length)
                        .map(sku => sku.substring(0,6));

                    this.httpCount = this.searchStyles.length;
                    this.httpComplete = 0;

                    for (let i = 0; i < this.searchStyles.length; i++){

                        this.$http.get(`rs/product/style/${this.searchStyles[i]}`)
                            .then(response => {
                                this.processData(response.data);

                        }).catch(error => {

                            let message;

                            if (error.response.status === 401) {
                                message = this.unauthorized;
                            } else {
                                message = "Invalid or uncreated REI 6-character style";
                            }

                            this.addMessage(message);
                            this.httpComplete--;
                            if (this.httpCount === this.httpComplete){
                                this.showSpinner = false;
                            }
                        });
                    }
                }
            },
            processData(data){

                this.httpComplete++;
                if (this.httpCount === this.httpComplete){
                    this.showSpinner = false;
                }

                data.skus.forEach(sku => {
                    this.skus.push({
                        brand: data.brandName,
                        style: data.style,
                        id: sku.sku,
                        color: sku.color,
                        casemark: sku.casemark,
                        desc: data.cleanDescription
                    });
                });
            },
            csvExport(){

                let rows = [this.headers];

                //create two-dim array that has values for each sku
                let skus = this.skus.map(sku => Object.keys(sku).map(key => sku[key]));

                rows = rows.concat(skus).map(row => row.join(","));

                let csvString = rows.join("\n");

                saveFile(csvString, "product-lookup.csv");
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../scss/base/vars";
    @import "../../scss/base/mixins";

    .finder-content {
        background-color: $mainBgColor;
        height: auto;
        min-height: 400px;
        @include box-shadow;

        .lookup-container {
            margin-top: 40px;

            #lookup-text {
                width: 80%;
                float: left;
            }

            #tip-title {
                font-weight:bold;
                color: $reiOrange;
                width:30px;
                float:left;
            }

            #tip-msg {
                width:330px;
                float:left;
            }

            #lookup-btn {
                float: left;
                margin: 4px 0 0 10px;
            }
        }
    }

    .download-btn {
        min-width: 90px;
        max-width: 150px;
        background-color: $adminOrange;
        color: white;
        font-family: $verandaFontStack;
        margin: 40px 0 20px 0;
    }

    table {
        font-family: 'Lato', Helvetica, Neue, Roboto, Arial, sans-serif;
        margin-top: 20px;
        font-size: 110%;
        @include box-shadow;

        thead {
            background-color: $mainOffsetColor;
        }

        tbody {
            background-color: white;
        }
    }

</style>