<template>
    <div>
        <div class="row">
            <div class="col-xs-10 col-xs-offset-1">
                <h1>Contact Us</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-8 col-xs-offset-1">
                <h3><b>Please review the <router-link v-bind:to="{name : routeNames.FAQ}">FAQ</router-link> page if you haven't already</b></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-10 col-xs-offset-1">
                <hr />
            </div>
        </div>

        <div class="row topics-input">
            <div class="col-xs-12 col-xs-offset-1 col-sm-2">
                <h2>Topics</h2>
            </div>

            <div class="col-xs-6 col-sm-2 col-xs-offset-1 col-sm-offset-2  ">
                <input type="text" v-model="name" class="form-control email-inputs" v-bind:class="{ 'input-border' : !name }" placeholder="Your Name" >
            </div>
            <div class="col-xs-6 col-sm-2 col-xs-offset-1 col-sm-offset-0 ">
                <input type="text" v-model="email" class="form-control email-inputs" v-bind:class="{ 'input-border' : !email }" placeholder="Your Email Address">
            </div>
        </div>

        <contact-topic  v-for="topic in topics"
                        :key="topic.name"
                        :topic="topic"></contact-topic>
    </div>
</template>

<script>

    import ContactUsTopics from "../../data/ContactUsTopics";
    import ContactTopic from "./ContactTopic.vue";
    import routeNames from "../../data/routeNames";

    export default {
        name: "contact",
        data() {
            return {
                topics: ContactUsTopics,
                routeNames
            }
        },
        components: {
            ContactTopic
        },
        computed: {
            name: {
                get() {
                    return this.$store.state.name;
                },
                set(value) {
                    this.$store.commit("setName", value);
                }
            },
            email: {
                get() {
                    return this.$store.state.email;
                },
                set(value) {
                    this.$store.commit("setEmail", value);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    /*.topic-section img {*/
    /*border: 2px solid gray;*/
    /*margin-left: 3%;*/
    /*width: 95%;*/
    /*max-width: 1000px;*/
    /*}*/

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";


    hr {
        border: 1px solid gray;
        margin: 0;
    }

    .topics-input {

        margin-bottom: 15px;

        .input-border {
            border: 3px solid $reiOrange;
        }

        .email-inputs {
            margin-top: 25px;
        }
    }
</style>