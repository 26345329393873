<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row">
                <div class="col-xs-12">
                    <h3 class="title">Upload Data</h3>
                    <p class="text-info">Historical upload data from the last two months</p>
                </div>
            </div>

            <div class="row term-container">
                <div class="col-xs-10 col-sm-6">
                    <input type="text" v-model="filterTerm" class="form-control" placeholder="Filter by vendor name, email, brand, filename or style number"/>
                </div>
                <div class="col-xs-1" >
                    <img title="refresh" :class="{'reload-image': true, 'spin-image' : loadingData }" @click="fetchData" :src="reloadImg" width="22" height="17">
                </div>
            </div>

            <div class="row btn-container">
                <div class="col-sm-2 col-sm-offset-1">
                    <input type="button" :class="btnClass(filterType.PI)" value="PI" @click="filterByType(filterType.PI)"/>
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.IMAGE)" value="Images" @click="filterByType(filterType.IMAGE)"/>
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.VIDEO)" value="Videos" @click="filterByType(filterType.VIDEO)"/>
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.LOGO)" value="Logos" @click="filterByType(filterType.LOGO)"/>
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.PDF)" value="PDF" @click="filterByType(filterType.PDF)"/>
                </div>
            </div>

            <div class="row">

                <div v-if="loadingData" class="col-xs-12">
                    <h3>Loading...</h3>
                </div>

                <div v-else-if="!filtered.length" class="col-xs-12">
                    <h3>No Results</h3>
                </div>

                <div v-else class="col-xs-12 table-container">
                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>Upload Date</th>
                                <th>Brand</th>
                                <th>Filename</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Notes</th>
                                <th v-if="selectedType.name === filterType.VIDEO.name || selectedType.name === filterType.PDF.name">Style</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="upload in filtered" :key="upload.id" :class="{'failed-upload' : upload.status === 'Failed'}">
                                <td>{{ upload.uploadDate }}</td>
                                <td>{{ upload.brand }}</td>
                                <td>{{ upload.filename }}</td>
                                <td>{{ upload.name }}</td>
                                <td><a :href="'mailto:' + upload.email">{{ upload.email }}</a></td>
                                <td>{{ upload.notes }}</td>
                                <td v-if="selectedType.name === filterType.VIDEO.name || selectedType.name === filterType.PDF.name">{{ upload.styleId }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

    import reloadImg from "../../../resources/static/img/reload.png";
    import formatDate from "../../util/formatDate";
    import types from "../../data/UploadTypes";

    export default {
        name: "upload-data",
        data() {
            return {
                uploadData: [],
                filterType: types,
                selectedType: types.PI,
                filterTerm: "",
                loadingData: true,
                reloadImg
            }
        },
        computed: {
            filtered() {
                let filterList = this.uploadData;
                const mapItem = (item) => {
                    return {
                        ...item,
                        uploadDate: formatDate(item.uploadDate),
                    };
                };
                if (this.filterTerm) {
                    filterList = filterList
                        .filter(upload => upload.filterTerm.includes(this.filterTerm.toLowerCase()))
                        .map(mapItem);
                }
                if (this.selectedType) {
                    filterList = filterList
                        .filter(upload => upload.type.includes(this.selectedType.name))
                        .map(mapItem);
                }
                return filterList;
            }
        },
        methods: {
            btnClass(type) {
                return {
                    'form-control' : true,
                    'admin-btn' : true,
                    'btn-selected' : this.selectedType.name === type.name
                }
            },
            filterByType(type) {
                this.selectedType = type;
            },
            fetchData() {

                this.loadingData = true;

                this.$http.get("rs/asset/data/upload")
                    .then(response => {

                        this.uploadData = response.data;

                        //add the filter term
                        this.uploadData.forEach(upload => {
                            upload.filterTerm = `${upload.name}${upload.brand}${upload.filename}${upload.email}${upload.styleId}`.toLowerCase();
                        });

                        this.loadingData = false;

                    }).catch(error => {

                        this.addMessage("Error fetching asset uploaded data");
                        this.loadingData = false;
                    });
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        },
        mounted() {
            this.fetchData();
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    .term-container {
        margin-top: 20px;
    }

    .btn-container {
        margin: 20px 0;

        .admin-btn {
            margin: 0 auto;

            @media (max-width: 768px) {
                margin-bottom: 10px;
            }
        }

        .btn-selected {
            color: black;
            background-color: #ffa450;
        }
    }

    .table-container {
        height: auto;
        max-height: 800px;
        overflow: auto;

        table {
            display: block;

            thead {
                background-color: $mainOffsetColor;
                font-weight: bold;

                th {
                    height: 50px;
                }
            }

            tbody {
                background-color: white;
            }
        }

        .failed-upload {
            background-color: rgba(255, 71, 55, 0.83);
            color: white;
        }
    }

</style>