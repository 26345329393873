<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row">
                <div class="col-xs-12">
                    <h3 class="title">Upload Resources</h3>
                    <p class="text-info">Upload files to the site for vendors to access. Existing files are replaced.</p>
                </div>
            </div>

            <resource-file :title="'Product Information Requirements - FAQ page'"
                           :filename="filenames.PI_REQ"></resource-file>

        </div>
    </div>

</template>

<script>

    import ResourceFile from "./ResourceFile.vue";
    import ResourceFilenames from "../../data/ResourceFilenames";

    export default {
        name: "upload-resources",
        data() {
            return {
                filenames: ResourceFilenames
            }
        },
        components: {
            ResourceFile
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .admin-panel {
        min-height: 250px;
        height: 100%;
        padding-bottom: 15px;

    }

</style>