<template>

    <div>
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 finder-content">

                <div class="row">
                    <div class="col-xs-10 col-xs-offset-1">
                        <div class="title-main">Product Information: Download a Template</div>
                        <div class="title-sub">Select the category and subcategory that best describe your products. Each spreadsheet contains worksheets with subcategories.</div>
                    </div>
                </div>
                <div class="row finder-dropdowns">
                    <div class="col-sm-4 col-xs-offset-1 col-xs-5">
                        <select v-on:change="selectFolder" v-model="selectedFolder" data-ui="category-dropdown" class="form-control" size="16">
                            <option v-for="(folder, index) in folders" :key=index v-bind:value="folder">{{ formatCategory(folder) }}</option>
                        </select>
                    </div>
                    <div class="col-sm-4 col-xs-5 table-container">
                        <table v-if="selectedFolder">
                            <tr class="header-table">
                                <th>Name</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                            <tr v-for="template in templates" v-bind:value="template">
                                <td>
                                    {{ formatExcelFileName(template.split("|")[0]) }}
                                </td>
                                <td>
                                    {{ template.split("|")[1] }}
                                </td>
                                <td class="actions">
                                    <div v-on:click="downloadTemplate(template)" data-ui="download-button">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <title/><g id="Complete"><g id="download"><g><path d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7" fill="none" stroke="#ec7210" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><g>
                                            <polyline data-name="Right" fill="none" id="Right-2" points="7.9 12.3 12 16.3 16.1 12.3" stroke="#ec7210" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <line fill="none" stroke="#ec7210" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="2.7" y2="14.2"/></g></g></g></g>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 upload-message">
                <p>Once your spreadsheet is filled out and complete, upload it
                    <router-link v-bind:to="{name : routeNames.UPLOAD, params: { type: 'pi' }}">here</router-link>.
                </p>
            </div>
        </div>
    </div>

</template>

<script>

    import saveFile from "../util/saveFile";
    import routeNames from "../data/routeNames";
    import Util from "../util/util";

    export default {
        name: "template-finder",
        data() {
            return {
                selectedFolder: "",
                folders: [],
                templates: [],
                data: {},
                routeNames
            }
        },
        methods: {
            selectFolder() {

                //set the template dropdown
                this.templates = this.data[this.selectedFolder];
            },
            downloadTemplate(template) {

                if (this.selectedFolder && template){
                    const selectedTemplateName = template.split("|")[0].trim();
                    this.$http({
                        url: `rs/template/download/${this.selectedFolder}/${selectedTemplateName}`,
                        method: "GET",
                        responseType: "blob"
                    }).then(response => {

                        saveFile(response.data, selectedTemplateName);

                    }).catch(error => {
                        this.addMessage("Error downloading template");
                    });
                }
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            },

            formatExcelFileName(fileName){
              let formattedFileName = Util.removeUnderscore(fileName);
              return Util.removeXlsx(formattedFileName);
            },

            formatCategory(category){
              return Util.removeUnderscore(category);
            },

            async loadTemplateData(){
              try {
                // Make the HTTP request using $http.get
                const response = await this.$http.get("/rs/template/data");
                // Assuming the response contains an array of items
                const dataConverted = {};
                for (const key of response.data) {
                  dataConverted[key.templateCategory] = key.templates.map(element => element.templateName + (element.modifiedDate !== undefined ? " | " + element.modifiedDate : ""));
                }
                this.data = dataConverted;
                this.folders = Object.keys(this.data);
              } catch (error) {
                let message;

                if (error.response.status === 401) {
                  message = this.$store.state.unauthorized;
                } else {
                  message = "Error listing templates";
                }
                this.addMessage(message);
              }
            }
        },
        created(){
          this.loadTemplateData();
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../scss/base/mixins";
    @import "../../scss/base/vars";

    .finder-content, .upload-message {
        background-color: $mainBgColor;
        @include box-shadow;
    }

    .finder-content {
        height: 100%;
        padding-bottom: 20px;
        margin-bottom: 5px;

        .finder-dropdowns {
            margin-top: 40px;
            font-family: $verandaFontStack;
        }

        .download-btn {
            min-width: 90px;
            max-width: 150px;
            background-color: $adminOrange;
            color: white;
            font-family: $verandaFontStack;

            @media (max-width: 768px) {
                margin: 20px auto 0 auto;
            }
        }
    }

    .upload-message {
        height: 40px;
        font-size: 100%;
        font-family: verdana, sans-serif;
        text-align: center;
        margin-top: 3px;

        p {
            margin-top: 10px;
        }
    }

    .table-container {
        height: 297px;
        min-width: 455px;
        overflow-x: auto;
        padding: 0;
    }

    table {
        position: relative;
        border-collapse: collapse;
        width: 100%;
    }
    tr.header-table th {
        background: #cecece;
        color: #000;
    }

    .header-table {
        position: sticky;
        top: -1px;
    }

    td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 3px;
        background: #fff;
    }

    th {
        border: 1px solid #dddddd;
        text-align: center;
        padding: 3px;
    }

    .actions {
        text-align: center;
    }

    @media screen and (max-width: 800px) {
        .table-container {
            padding: 5rem;
        }
    }
</style>