<template>

    <div>
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 news-container" v-show="news">
                <h2 class="news-display" data-ui="news-display-text"><span class="news-heading">News:</span> <span class="news-text">{{ news }}</span></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 col-sm-offset-1 home-img" :style="{ backgroundImage: backpackerImg }"></div>

            <div class="col-sm-5 home-links">
                <link-content :content="piContent"></link-content>
                <link-content :content="imageContent"></link-content>
            </div>
        </div>
    </div>

</template>

<script>

    import imageContent from "../../data/ImageHomeContent";
    import piContent from "../../data/PiHomeContent";
    import LinkContent from "./LinksContent.vue";

    import bpBg from "../../../resources/static/img/backpackers.jpg";

    export default {
        name: "home",
        data() {
            return {
                piContent,
                imageContent,
                backpackerImg: this.createCssUrl(bpBg)
            }
        },
        methods: {
            createCssUrl(image) {
                return 'url(' + image + ')';
            },
            getNews() {
                this.$http.get("/rs/message?type=NEWS").then(response => {

                    const message = response.data;
                    if (message && message.content && message.content.length) {
                        this.$store.commit("setNews", message.content);
                    }
                });
            }
        },
        computed: {
            news() {
                return this.$store.state.news;
            }
        },
        components: {
            LinkContent
        },
        mounted() {
            this.getNews();
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    .news-container {
        background-color: $mainBgColor;
        margin-top: 0px;
        margin-bottom: 20px;
        @include box-shadow;
    }

    .news-display {
        margin-top: 10px;

        .news-heading {
            color: #2F8800;
        }

        .news-text {
            color: #33CC00;
        }
    }

    .home-img, .home-links {
        height: 540px;
    }

    .home-img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @include box-shadow;
        margin-right: 15px;
    }

</style>
