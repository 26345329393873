<template>
    <div>
        <div class="row">
            <div class="col-xs-10 col-xs-offset-1">
                <h1>Update Existing Product Information</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-8 col-xs-offset-1">
                <h3><b>Please fill out applicable section(s) below</b></h3>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-10 col-xs-offset-1">
                <hr/>
            </div>
        </div>

        <div class="row user-input">
            <div class="col-xs-6 col-sm-2 col-xs-offset-1">
                <input type="text" data-ui="name-field" v-model="name" class="form-control email-inputs"
                       v-bind:class="{ 'input-border' : !name }" placeholder="Your Name">
            </div>
            <div class="col-xs-6 col-sm-2 col-xs-offset-1 col-sm-offset-0">
                <input type="text" data-ui="email-field" v-model="email" class="form-control email-inputs"
                       v-bind:class="{ 'input-border' : !email }" placeholder="Your Email Address">
            </div>
            <div class="col-xs-6 col-sm-4 col-md-2 page-label">
                <h4 class="email-inputs">REI.com Product Page URL</h4>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-4">
                <input type="text" data-ui="page-field" v-model="productPage" class="form-control email-inputs"
                       v-bind:class="{ 'input-border' : !productPage }"
                       placeholder="https://www.rei.com/product/999999">
            </div>
        </div>

        <div class="row">
            <div class="col-xs-10 col-xs-offset-1 panel">
                <div class="row">
                    <div class="col-xs-10">
                        <h3 class="title">Specs</h3>
                        <p class="text-info">Please enter the spec name you want to update then enter the current value
                            and the new value.<br>
                            If the product you are updating is changing significantly from previous version(s), you may
                            need to create a new item number. Please contact your REI buyer for more information.<br>
                            If update applies to a certain size, please indicate which size in the Notes field.
                        </p>
                    </div>
                    <div class="col-xs-4 col-sm-2 button-container">
                        <input type="button" @click="addSpecRow" class="form-control btn addrow-button" value="Add New Row"
                               :disabled="addSpecRowDisabled" data-ui="add-spec-row-button"/>
                    </div>
                </div>

                <div class="col-xs-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Spec Name</th>
                            <th>Current Value (if new Spec, write "New")</th>
                            <th>New Value</th>
                            <th>Notes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(specRow, index) in specRows">
                            <td><input type="text" :data-ui="'spec-name-field-' + index" v-model="specRow.specName" class="form-control"/></td>
                            <td><input type="text" :data-ui="'spec-current-field-' + index" v-model="specRow.currentValue" class="form-control"/></td>
                            <td><input type="text" :data-ui="'spec-new-field-' + index" v-model="specRow.newValue" class="form-control"/></td>
                            <td><input type="text" :data-ui="'spec-notes-field-' + index" v-model="specRow.specNotes" class="form-control"/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-xs-10 col-xs-offset-1 panel">
                <div class="row">
                    <div class="col-xs-10">
                        <h3 class="title">Copy</h3>
                        <p class="text-info">REI will make updates only when information is either INCORRECT or MISSING
                            from our pages.<br>
                            Before using this form, please audit the REI page and send only corrections (bullets
                            requiring revision or removal) or additions (vital features that are missing in the current
                            copy).<br>
                            We are not able to support revisions to simply refresh marketing language.<br>
                            Remember: The wording in the copy you submit may be modified to meet our extensive style
                            guide, site requirements and customer needs, but the facts and intent will be preserved.
                        </p>
                    </div>
                    <div class="col-xs-4 col-sm-2 button-container">
                        <input type="button" @click="addCopyRow" class="form-control btn addrow-button" value="Add New Row"
                               :disabled="addCopyRowDisabled" data-ui="add-copy-row-button"/>
                    </div>
                </div>

                <div class="col-xs-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Current Feature Bullet (if info is new, write "New")</th>
                            <th>New Feature Bullet (write "Delete" if being replaced)</th>
                            <th>Reason for Update</th>
                            <th>Notes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(copyRow, index) in copyRows">
                            <td><input type="text" :data-ui="'copy-current-field-' + index" v-model="copyRow.currentFeatureBullet" class="form-control"/></td>
                            <td><input type="text" :data-ui="'copy-new-field-' + index" v-model="copyRow.newFeatureBullet" class="form-control"/></td>
                            <td>
                                <select :data-ui="'copy-reason-field-' + index" v-model="copyRow.updateReason" class="form-control">
                                    <option>Incorrect Information</option>
                                    <option>Missing Information</option>
                                    <option>Other; please indicate reason in Notes</option>
                                </select>
                            </td>
                            <td><input type="text" :data-ui="'copy-notes-field-' + index" v-model="copyRow.copyNotes" class="form-control"/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-xs-10 col-xs-offset-1 panel">
                <div class="row">
                    <div class="col-xs-10">
                        <h3 class="title">Product Name</h3>
                        <p class="text-info">If the casemark (vendor’s unique identifier) for a product has not changed
                            but the name has—and this new name is now reflected on your website—please tell us using the
                            form below.</p>
                    </div>
                </div>

                <div class="col-xs-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>New Product Name</th>
                            <th>Notes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><input type="text" data-ui="product-name-field" v-model="productName" class="form-control"/></td>
                            <td><input type="text" data-ui="product-notes-field" v-model="productNotes" class="form-control"/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-xs-10 col-xs-offset-1">
                <div class="col-xs-4 col-sm-2 submit-button-container">
                    <input type="button" @click="submit" class="form-control btn submit-button" value="Submit"
                           :disabled="submitDisabled" data-ui="submit-button"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import Util from "../../util/util";

    export default {
        name:     "product-info",
        data () {
            return {
                productPage:  "",
                specRows:     [],
                copyRows:     [],
                productName:  "",
                productNotes: ""
            }
        },
        created () {
            this.specRows.push( {} );
            this.copyRows.push( {} );
        },
        methods:  {
            addSpecRow () {
                this.specRows.push( {} );
            },
            addCopyRow () {
                this.copyRows.push( {} );
            },
            submit () {
                let errorMessage = this.validateInputs();
                if ( errorMessage ) {
                    this.addMessage( errorMessage );
                    return;
                }

                let request = {
                    name:            this.name,
                    email:           this.email,
                    productPage:     this.productPage,
                    newProductName:  this.productName,
                    newProductNotes: this.productNotes,
                    specs:           this.specRows,
                    copy:            this.copyRows
                };

                this.$http.post( "/rs/updateProductInfo", request )
                    .then( response => {
                            this.addMessage( 'Request submitted. Thanks!' );
                        },
                        error => {
                            this.addMessage( 'Submit Failed. ' + error.response.data);
                        }
                    )
            },
            addMessage ( message ) {
                this.$store.commit( "addMessage", message );
            },
            validateInputs () {
                if (!Util.validateEmail(this.email)) {
                    return "Please enter a valid email address";
                }

                for ( let specRow of this.specRows ) {
                    if ( specRow.specName ) {
                        if ( !specRow.currentValue || !specRow.newValue ) {
                            return "Please enter Spec Current Value and New Value.";
                        }
                    }
                }

                for ( let copyRow of this.copyRows ) {
                    if ( copyRow.currentFeatureBullet && !copyRow.newFeatureBullet ) {
                        return "Please enter Copy New Feature Bullet.";
                    }
                    if ( (copyRow.currentFeatureBullet || copyRow.newFeatureBullet) && !copyRow.updateReason ) {
                        return "Please enter Copy Reason for Update.";
                    }
                    if ( copyRow.updateReason === "Other; please indicate reason in Notes" && !copyRow.copyNotes ) {
                        return "Please indicate the Copy reason in Notes.";
                    }
                }
            }
        },
        computed: {
            name:  {
                get () {
                    return this.$store.state.name;
                },
                set ( value ) {
                    this.$store.commit( "setName", value );
                }
            },
            email: {
                get () {
                    return this.$store.state.email;
                },
                set ( value ) {
                    this.$store.commit( "setEmail", value );
                }
            },
            addSpecRowDisabled () {
                for ( let specRow of this.specRows ) {
                    if ( !specRow.specName || !specRow.currentValue || !specRow.newValue ) {
                        return true;
                    }
                }
                return false;
            },
            addCopyRowDisabled () {
                for ( let copyRow of this.copyRows ) {
                    if ( !copyRow.currentFeatureBullet || !copyRow.newFeatureBullet || !copyRow.updateReason ) {
                        return true;
                    }
                    if ( copyRow.updateReason === "Other; please indicate reason in Notes" && !copyRow.copyNotes ) {
                        return true;
                    }
                }
                return false;
            },
            submitDisabled () {
                return !this.name || !this.email || !this.productPage;
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    hr {
        border: 1px solid gray;
        margin: 0;
    }

    th {
        text-align: center;
    }

    .user-input {

        margin-bottom: 15px;

        .input-border {
            border: 3px solid $reiOrange;
        }

        .email-inputs {
            margin-top: 25px;
        }

        .page-label {
            text-align: right;
            padding-right: 0px;
            margin-top: 10px;
        }

    }

    .panel {
        background-color: $mainBgColor;
        font-family: 'Lato', Helvetica, Neue, Roboto, Arial, sans-serif;
        font-size: 95%;
        @include box-shadow;
        margin-bottom: 20px;

        .title {
            font-weight: bold;
        }

    }

    .button-container {
        margin-top: 20px;
        float: right;
    }

    .submit-button-container {
        padding-right: 0px;
        float: right;
    }

    .addrow-button, .submit-button {
        @include reiButton;
    }
</style>