<template>

    <div class="row links-content">
        <div class="col-sm-12">
            <div class="links-img">
                <img :src="content.image">
            </div>
            <div class="links-text">
                <div class="links-header">
                    <p class="links-title" >{{ content.title }}</p>
                    <p class="links-sub" >{{ content.subTitle }}</p>
                </div>

                <div v-for="link in content.links" :key="link.title" class="links-text-link">
                	<a v-if="link.external" :href="link.to" target="_blank">{{ link.title }}</a>
					<router-link v-else v-bind:to="link.to" :data-ui="link.title.toLowerCase().replace(/ /g, '-')">{{ link.title }}</router-link>
                </div>

                <slot name="additional"></slot>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "links-content",
        props: ["content"]
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    .links-content {
        background-color: $mainBgColor;
        margin: 0 0 20px 1px;
        height: 260px;
        @include box-shadow;

        .links-img {
            text-align: center;
            float: left;
            height: 220px;
            width: 15%;

            img {
                margin-top: 30px;
            }
        }

        .links-text {
            width: 85%;
            float: left;

            .links-title {
                font-size: 180%;
                margin-top: 10px;
            }

            .links-sub {
                font-family: $verandaFontStack;
            }

            .links-text-link {
                line-height:23px;
                text-decoration: none;
                font-size: 16px;
                font-family: $arialFontStack;
            }

            .links-text-link a {
                text-decoration: none;
                font-size: 16px;
                color: $reiOrange;
            }
        }
    }

</style>