<template>
    <modal v-if="showModal">
      <template v-slot:body>
        <div class="modal-reset-div">

            <div class="row">
                <div class="col-xs-12">
                    <h2 class="modal-title">Reset</h2>
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-10 col-xs-offset-1">
                    <h3 class="modal-text">Are you sure you want to reset all changes made to the size chart?</h3>
                </div>
            </div>
            <div class="row modal-btns">
                <div class="col-xs-offset-11 col-xs-offset-1 col-sm-4 col-sm-offset-2">
                    <input type="button" class="btn btn-primary" value="Confirm Reset" @click="resetChart"/>
                </div>
                <div class="col-xs-offset-11 col-xs-offset-1 col-sm-offset-0 col-sm-4">
                    <input type="button" class="btn btn-danger" value="Cancel" @click="closeModal" />
                </div>
            </div>

        </div>
      </template>
    </modal>
</template>

<script>

    import Modal from "../elements/Modal.vue";

    export default {
        name: "ResetModal",
        props: ["showModal"],
        components: {
            Modal
        },
        methods: {
            resetChart() {
                this.$emit("reset-chart");
            },
            closeModal() {
                this.$emit("close-modal");
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";

    .modal-reset-div {

        .modal-title {
            margin: 5px 10px;
            font-family: $andersonFontStack;
        }

        hr {
            padding: 0;
            margin: 0;
            border: 1px solid lightgray;
        }

        .modal-text {
            text-align: center;
            font-family: $latoFontStack;
        }

        .modal-btns {
            text-align: center;
            margin-top: 30px;

            .btn {
                margin-bottom: 10px;
            }
        }
    }
</style>