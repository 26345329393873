import imgImage from "../../resources/static/img/img.gif";

export default {
    title: "Photos & Videos",
    subTitle: "Upload your images and videos",
    image: imgImage,
    links: [
    	{
            title: "REI Content Requirements & Style Guides",
            to: "https://public.cloud-dam.rei.com/api/public/content/VendorContentRequirements",
            external: true
        },
        {
            title: "Find REI 10-character SKU",
            to: {name: 'sku-finder'}
        },
        {
            title: "Upload Product Images",
            to: {name: 'upload', params: { type: 'image' }}
        },
        {
            title: "Upload Product Videos",
            to: {name: 'upload', params: { type: 'video' }}
        },
        {
            title: "Upload Logos",
            to: {name: 'upload', params: { type: 'logo' }}
        },
        {
            title: "Upload Product Manual PDFs",
            to: {name: 'upload', params: {type: 'pdf'}}
        },
        {
            title: "Vendor Clone Request",
            to: {name: 'clone-request'}
        }
    ]
};