import { createStore } from "vuex";

export default createStore({
    state: {
        name: "",
        email: "",
        unauthorized: "Unauthorized. Please log in",
        role: "",
        messages: [],
        username: "",
        news: ""
    },
    mutations: {
        setName(state, message) {
            state.name = message;
        },
        setEmail(state, message) {
            state.email = message;
        },
        setAdmin(state, message) {
            state.admin = message;
        },
        setRole(state, role) {
            state.role = role;
        },
        addMessage(state, message) {
            state.messages.push(message);
        },
        removeMessages(state, numToRemove) {
            //remove the messages that have been viewed
            state.messages.splice(0, numToRemove);
        },
        setUsername(state, message) {
            state.username = message;
        },
        setNews(state, message) {
            state.news = message;
        }
    }
});