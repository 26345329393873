<template>
        <div class="contact-container">
            <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-3">
                    <h4 class="contact-label">{{ title }}</h4>
                </div>
                <div class="col-xs-12 col-sm-8 col-md-9">
                    <input type="file"
                           height="40"
                           data-ui="choose-files-button"
                           @change="processFiles($event.target.files)"
                           class="form-control contact-file">
                </div>
            </div>
        </div>
</template>

<script>

    const exts = ["jpg", "jpeg", "png", "tif", "tiff", "bmp", "xlsx", "xls"];

    export default {
        name: "contact-file",
        props: ["title", "fileObj"],
        data() {
            return {

            }
        },
        methods: {
            processFiles(files) {

                let file = files[0];

                if (exts.includes(this.getExtension(file.name))) {
                    this.$emit("addFile", file, this.fileObj);
                } else {
                    this.addMessage(`Error: only images supported: ${exts.join(", ")}`);
                }
            },
            getExtension(filename){
                return filename.substring(filename.lastIndexOf(".", filename) + 1).toLowerCase();
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .contact-container {
        margin-bottom: 20px;
        background-color: $mainOffsetColor;
        padding: 5px;
        border-radius: 3px;
        border: 1px solid darken($mainOffsetColor, 10%);
        height: 100%;

        .contact-label {
            float: left;
            margin-top: 14px;
            font-weight: bold;
        }

        .contact-file {
            float: left;
            width: 100%;
            margin-top: 5px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    //firefox needs this to display file input with form-control properly
    @-moz-document url-prefix() {
        .contact-file {
            padding: 0;
        }
    }

</style>