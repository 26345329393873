import uploadTypes from "./UploadTypes";
import productInfo from "./ProductInfo";

//config data for the uploader, config chosen with url path variable

export default Object.freeze({
    image: {
        uploadType: uploadTypes.IMAGE,
        productInfo: productInfo.SKU,
        title: "Upload Images with filenames that start with a 10-character REI SKU, with longest side at least 900 pixels",
        accepted: ["tif", "tiff", "psd", "jpg", "jpeg", "png"]
    },
    pi: {
        uploadType: uploadTypes.PI,
        productInfo: productInfo.BRAND,
        title: "Upload Product Information Spreadsheets",
        accepted: ["xlsx", "xls"]
    },
    video: {
        uploadType: uploadTypes.VIDEO,
        productInfo: productInfo.ENTER_INFO,
        title: "Upload Product Videos",
        accepted: ["mp4", "mov", "wmv", "avi"]
    },
    logo: {
        uploadType: uploadTypes.LOGO,
        productInfo: productInfo.BRAND,
        title: "Upload Logos",
        accepted: ["ai", "eps"]
    },
    pdf: {
        uploadType: uploadTypes.PDF,
        productInfo: productInfo.ENTER_INFO,
        title: "Upload Product Manual PDFs",
        accepted: ["pdf"]
    }
});
