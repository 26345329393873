<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 faq-container">
            <h1>FAQ</h1>

            <div class="faq" v-for="faq in faqs">
                <h3>{{ faq.q }}</h3>
                <p v-html="faq.a"></p>
                <router-link v-if="faq.link" v-bind:to="faq.link.to">{{ faq.link.title }}</router-link>
                <a v-if="faq.externallink" :href="faq.externallink.to" target="_blank">{{ faq.externallink.title }}</a>
                <div v-if="faq.file">
                    <a @click="download(faq.file)">{{ faq.file.title }}</a>
                    <p v-if="faq.file.downloading" class="downloading">downloading...</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import faqs from "../data/FaqContent";
    import saveFile from "../util/saveFile";

    export default {
        name: "faq",
        data() {
            return {
                faqs
            }
        },
        methods: {
            download(file) {

                file.downloading = true;

                this.$http({
                    url: `rs/resource/download/${file.filename}`,
                    method: "GET",
                    responseType: "blob"
                }).then(response => {

                    file.downloading = false;
                    saveFile(response.data, file.filename);

                }).catch(error => {

                    file.downloading = false;
                    this.addMessage("Error downloading resource");
                });
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../scss/base/vars";
    @import "../../scss/base/mixins";

    .faq-container {

        .faq {
            background-color: $mainBgColor;
            width: 100%;
            margin-bottom: 20px;
            padding: 15px;
            transition: all 0.15s;
            @include box-shadow;

            &:hover {
                @include box-shadow-hover;
            }

            h3 {
                margin-left: 15px;
                font-weight: bold;
            }

            p, a {
                font-family: $verandaFontStack;
                margin-left: 30px;
                font-size: 120%;
            }

            a {
                margin-bottom: 15px;
                display: inline-block;
                &:hover {
                    cursor: pointer;
                }
            }

            .downloading {
                display: inline-block;
                color: #00b600;
                font-weight: bold;
            }
        }
    }

</style>