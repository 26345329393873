<template>

    <div class="row">
        <div class="col-sm-10 col-sm-offset-1 sku-finder-message" :class="classes">
            <div class="row">
                <div class="col-xs-8 col-xs-offset-2">
                    <h2 class="message">
                        <slot></slot>
                    </h2>
                </div>
                <div class="col-xs-2">
                    <input type="button" class="form-control sku-close" :class="btnClasses" value="Close" @click="close"/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "LinkMessage",
        props: ["type", "display"],
        data() {
            return {
                messageType: this.type.toLowerCase()
            }
        },
        computed: {
            classes() {
                return {
                    "alert-danger": this.messageType === "danger",
                    "alert-info": this.messageType === "info",
                    "hide-sku-message": !this.display,
                    "display-sku-message": this.display
                }
            },
            btnClasses() {
                return {
                    "alert-danger": this.messageType === "danger",
                    "alert-info": this.messageType === "info"
                }
            }
        },
        methods: {
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

    .sku-finder-message {
        border-radius: 3px;
        text-align: center;
        overflow: hidden;
        transition: all 0.5s;

        .message {
            margin-top: 10px;
        }

        .sku-close {
            text-align: center;
            font-size: 175%;
            padding-top: 0;
            margin-top: 10px;
            width: 50%;
            min-width: 75px;
            float: right;
            border: none;
            box-shadow: none;
            background: transparent;
        }
    }

    .hide-sku-message {
        height: 0;
        border: none;
        background-color: white;
        margin-bottom: 0;
    }

    .display-sku-message {
        min-height: 57px;
        height: auto;
        margin-bottom: 15px;
        border: 2px solid gray;
    }

</style>