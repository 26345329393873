<template>

    <div>
        <div v-if="isLoginPage" class="row menu-bar">
            <div class="col-sm-1 col-sm-offset-10" style="padding-right: 0;">
                <div class="menu-link">
                    <a href="mailto:CAT@rei.com">Contact</a>
                </div>
            </div>
        </div>

        <div v-else class="row menu-bar">
            <div data-ui="header-links-component" class="col-sm-6 col-sm-offset-5 menu-link">
                <a class="logout" @click="logout" data-ui="log-out-button">Log out</a>
                <router-link v-bind:to="{name : 'contact'}" data-ui="contact-link">Contact</router-link>
                <router-link v-bind:to="{name : 'how-to'}" data-ui="how-to-link" >How To</router-link>
                <router-link v-bind:to="{name : 'faq'}" data-ui="faq-link">FAQ</router-link>
                <router-link v-bind:to="{name : 'home'}" data-ui="home-link">Home</router-link>
                <router-link v-if="isAdmin" v-bind:to="{name : 'admin'}" data-ui="admin-link">Admin</router-link>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
                <router-link v-bind:to="{name : 'home'}">
                    <div class="header-image">
                        <img src="../../../resources/static/img/rei-logo.gif" alt="REI Logo">
                    </div>
                    <div class="header-title">VENDOR PRODUCT PORTAL</div>
                </router-link>
            </div>
        </div>
        <message-modal></message-modal>
    </div>

</template>

<script>

    import MessageModal from './MessageModal.vue';

    export default {
        name: 'app-header',
        computed: {
            isLoginPage() {
                return this.$route.name === "login";
            },
            isAdmin() {
                return this.$store.state.role === "admin";
            }
        },
        components: {
            MessageModal
        },
        methods: {
            logout() {

                this.$http.delete("/rs/auth")
                    .catch(error => {
                       console.log("error logging out");
                    });

                //reset role and redirect to login page
                this.$store.commit("setRole", "");
                this.$router.push("/");
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    @mixin navBorderRadiusTop() {
        border-top-left-radius: 7px!important;
        border-top-right-radius: 7px!important;
    }

    .menu-bar {
        margin: 0 auto;
        width: 100%;
        height:40px;
        background-color: #d4d4d4;
        background-image: -webkit-linear-gradient(top, #f7f7f7, #d4d4d4);
        background-image: -moz-linear-gradient(top, #f7f7f7, #d4d4d4);
        background-image: -ms-linear-gradient(top, #f7f7f7, #d4d4d4);
        background-image: -o-linear-gradient(top, #f7f7f7, #d4d4d4);
        background-image: linear-gradient(to bottom, #f7f7f7, #d4d4d4);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#f7f7f7, endColorstr=#d4d4d4);
    }

    .menu-link a {
        height:40px;
        line-height:40px;
        padding:0 12px;
        margin-right: 0!important;
        font-family: $verandaFontStack;
        float: right;
        font-weight: bold;
        font-size: 11px;
        text-align: center;
        color: gray;
        text-decoration: none;
        background-color: #c0c0c0;
        border-radius: 1px;
        background-image: -webkit-linear-gradient(top, #eeeeee, #c0c0c0);
        background-image: -moz-linear-gradient(top, #eeeeee, #c0c0c0);
        background-image: -ms-linear-gradient(top, #eeeeee, #c0c0c0);
        background-image: -o-linear-gradient(top, #eeeeee, #c0c0c0);
        background-image: linear-gradient(to bottom, #eeeeee, #c0c0c0);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#eeeeee, endColorstr=#c0c0c0);
    }

    .menu-link a:hover	{
        background-image: -webkit-linear-gradient(top, #ffffff, #ffffff);
        background-image: -moz-linear-gradient(top, #ffffff, #ffffff);
        background-image: -ms-linear-gradient(top, #ffffff, #ffffff);
        background-image: -o-linear-gradient(top, #ffffff, #ffffff);
        background-image: linear-gradient(to bottom, #ffffff, #ffffff);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#ffffff, endColorstr=#ffffff);
        @include navBorderRadiusTop;
    }

    .logout {
        &:hover {
            cursor: pointer;
        }
    }

    .header-image {
        float:left;
        margin-top: 10px;
        margin-right: 20px;
    }

    .header-title {
        float: left;
        font-size:290%;
        padding-top:18px;
        font-weight: bold;
        text-decoration: none;
        color: $defaultFontColor;
    }

    .router-link-exact-active {
        background-image: linear-gradient(to bottom, white, white)!important;
        @include navBorderRadiusTop;
    }

</style>