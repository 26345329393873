<template>

    <div>
        <div class="row">
            <div class="col-xs-10 col-xs-offset-1 size-chart-panel" data-ui="size-chart-table">

                <div class="row">
                    <div class="col-xs-10">
                        <h2 data-ui="size-chart-title" v-if="localChart">{{ localChart.title }}</h2>
                        <h2 data-ui="size-chart-title" v-else>{{ chartHeader }}</h2>
                    </div>
                    <div class="col-xs-2">
                        <img title="reload" class="reload-image" :class="{'spin-image' : loading, 'reload-hide' : !loading }" :src="reloadImg"/>
                    </div>
                </div>

                <div v-if="localChart">

                    <p v-if="editable" class="text-info">Please enter your size chart information in the template below, providing size information for only the sizes that REI carries.</p>
                    <p v-else class="text-info">You will only see sizes from your brand that REI currently carries, so some sizes your brand offers may not be shown here.</p>

                    <div :class="{'editable-table-container' : editable}">
                        <table class="table table-striped table-responsive">
                            <thead>

                                <tr v-if="editable">
                                    <th v-for="(header, index) in localChart.editableHeaders" :key="index">
                                        <!-- index 0 is a blank cell -->
                                        <select v-if="index > 0" ref="headerSelect" class="form-control" @change="setHeader(index, $event)" :disabled="!header.active">
                                            <option disabled selected>Select size</option>
                                            <option v-for="value in header.values" :key="value" :value="value">{{ value }}</option>
                                        </select>
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th v-for="header in localChart.header" :key="header">{{ header }}</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr v-for="(row, rowIndex) in localChart.rows" :key="rowIndex">
                                    <td v-for="(value, colIndex) in row" :key="colIndex">

                                        <span v-if="editable">
                                            <!-- index 0 is the row's title -->
                                            <textarea v-if="colIndex > 0" v-model="row[colIndex]" class="size-input" :tabindex="getTabIndex(rowIndex, colIndex)"></textarea>
                                            <span v-else>{{ value }}</span>
                                        </span>
                                        <span v-else>{{ value }}</span>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2>Additional Notes</h2>

                    <p v-if="editable" class="text-info">If you have notes specific to this size chart that you would like customers to see, please enter them in the field below. For example, "Measurements are stated in inches unless otherwise indicated."</p>
                    <p class="text-info">Please note: These notes are displayed to customers on the product page.</p>

                    <textarea class="form-control notes" rows="8" placeholder="" maxlength="1000" :disabled="!editable" v-model="localChart.notes" data-ui="notes-field"></textarea>

                    <div class="row">
                        <div class="col-xs-3">
                            <button v-if="editable"
                                    type="button"
                                    @click="updateChart"
                                    :disabled="updating"
                                    class="btn btn-primary"
                                    data-ui="submit-button">Submit</button>
                        </div>
                        <div class="col-xs-3 col-xs-offset-6">
                            <button v-if="editable"
                                    type="button"
                                    @click="showModal = true"
                                    class="btn btn-danger reset-btn"
                                    data-ui="submit-button">Reset</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <reset-modal :show-modal="showModal"
                     @close-modal="showModal = false"
                     @reset-chart="resetChart"></reset-modal>

    </div>

</template>

<script>

    import ResetModal from "./ResetModal.vue";
    import reloadImg from "../../../resources/static/img/reload.png";

    export default {
        name: "size-chart-table",
        props: ["chart", "loading", "updating", "chartHeader"],
        data() {
            return {
                editable: false,
                localChart: null,
                showModal: false,
                reloadImg
            }
        },
        components: {
            ResetModal
        },
        methods: {
            resetChart() {
                this.showModal = false;
                this.createChart();
            },
            chartIsEditable(rows) {

                for (let row of rows) {
                    for (let colIndex in row ) {
                        let column = row[colIndex];

                        //if column has a value and is not index 0 then chart has data
                        if (column && colIndex !== '0') {
                            return false;
                        }
                    }
                }
                return true;
            },
            setHeader(index, event) {

                let headers = this.localChart.editableHeaders;
                let value = event.target.value;
                let nextIndex = index + 1;

                headers[index].selected = value;
                headers[index].active = false;

                //enable the next select element
                if (nextIndex < headers.length) {
                    headers[nextIndex].active = true;
                }

                //remove the selected value from the unselected header values
                headers.filter(h => !h.selected).forEach(h => h.values.splice(h.values.indexOf(value), 1));
            },
            getTabIndex(row, col) {
                //calculate the tab index for tabs to go down instead of across the table cells
                return row + col * this.localChart.rows.length;
            },
            createStaticChart(chart) {

                let indices = [];
                let hasRowData;
                
                //reverse iteration to allow for the removal of rows without data
                for (let rowIndex = chart.rows.length - 1; rowIndex >= 0; rowIndex--) {

                    hasRowData = false;
                    let row = chart.rows[rowIndex];

                    //get array of all row indices that have values
                    for (let colIndex = 0; colIndex < row.length; colIndex++) {
                        if (row[colIndex]) {

                            //only include if index has not already been added
                            if (indices.indexOf(colIndex) === -1) {
                                indices.push(colIndex);
                            }

                            //don't include the header in the data check
                            if (colIndex !== 0) {
                                hasRowData = true;
                            }
                        }
                    }

                    //remove rows that don't have data
                    if (!hasRowData) {
                        chart.rows.splice(rowIndex, 1);
                    }
                }

                indices = indices.sort((a, b) => a - b);

                //filter the arrays by indices to remove empty values
                let indexCheck = (val, index) => indices.indexOf(index) !== -1;
                chart.header = chart.header.filter(indexCheck);
                chart.rows = chart.rows.map(row => row.filter(indexCheck));
                
                return chart;
            },
            createEditableChart(chart) {

                //reset selected option if a previous chart has selections
                if (this.$refs.headerSelect) {
                    this.$refs.headerSelect.forEach(s => s.selectedIndex = 0);
                }

                chart.editableHeaders = [];

                //create properties and clone the values for every entry in header
                for (let i = 0; i < chart.header.length; i++) {
                    chart.editableHeaders.push({
                        selected: null,
                        active: i === 1,
                        values: chart.header.slice(1)
                    });
                }
                return chart;
            },
            createChart() {
                //create a copy of the chart
                let chart = JSON.parse(JSON.stringify(this.chart));
                this.editable = this.chartIsEditable(chart.rows);
                this.localChart = this.editable ? this.createEditableChart(chart) : this.createStaticChart(chart);
            },
            updateChart() {

                //add from 1 to account for first index being an empty string
                const len = this.localChart.editableHeaders.reduce((acc, h) => h.selected ? acc + 1 : acc, 1);

                if (!this.isValidChart(len)) {
                    return;
                }

                //assign the selected headers to header
                this.localChart.header = this.localChart.editableHeaders
                    .filter(h => h.selected)
                    .map(h => h.selected);

                //the first index is an empty string placeholder for the row title
                this.localChart.header.unshift("");

                //remove empty rows on end to match selected header length, and removed extra editableHeaders for UI
                this.localChart.rows = this.localChart.rows.map(row => row.slice(0, len));
                this.localChart.editableHeaders = this.localChart.editableHeaders.slice(0, len);

                this.$emit("update-chart", this.localChart);
            },
            isValidChart(len) {

                if (len === 1) {
                    this.addMessage("Error: Must select at least one size header");
                    return;
                }

                //check if value was entered without a header
                for (let row of this.localChart.rows) {
                    for (let i = len; i < row.length; i++) {
                        if (row[i]) {
                            this.addMessage(`Error: Select a size header or delete the values you’ve entered, value '${row[i]}'`);
                            return false;
                        }
                    }
                }
                return true;
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        },
        watch: {
            chart() {
                if (this.chart) {
                    this.createChart();
                } else {
                    //new brand was selected
                    this.localChart = null;
                }

            },
            loading() {},
            updating() {}
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";

    $borderLightGray: #ddd;

    .editable-table-container {
        overflow-x: visible;
        overflow-y: hidden;
        margin-left: 23rem;
        margin-bottom: 2rem;

        table {
            tr {
                td:first-child, th:first-child {
                    position: absolute;
                    min-height: 7rem !important;
                    max-height: 7rem !important;
                    margin-left: 10px;
                    left: 0;
                    pointer-events: none;
                    background-color: #fff;
                    text-align: center;
                    padding: 20px !important;
                }

                td {
                    min-width: 20rem;
                    max-width: 20rem;
                    min-height: 6rem !important;
                    max-height: 6rem !important;
                    padding: 0 !important;

                    .size-input {
                        background-color: transparent;
                        min-width: 20rem;
                        max-width: 20rem;
                        min-height: 6rem!important;
                        max-height: 6rem!important;
                        padding: 1.2rem 1.6rem;
                        border: 0 solid;
                        resize: none;

                        &:focus {
                            outline: 0
                        }
                    }

                    &:first-child {
                        min-width: 23rem;
                        max-width: 23rem;
                    }
                }

                th:first-child {
                    //blank top left cell
                    min-width: 23rem;
                    max-width: 23rem;
                    border-top: .1rem solid #ddd !important;
                    margin-top: -.1rem;
                }
            }
        }
    }

    table {
        //for table-responsive
        display: block;

        tr {
            border: 1px solid $borderLightGray;

            td, th {
                min-width: 100px;
                border: 1px solid $borderLightGray;
            }
        }
    }

    .notes {
        margin-bottom: 20px;
    }

    .reload-image {
        margin-top: 27px;
        margin-right: 10px;
        float: right;
        opacity: 1;
        transition: opacity 0.1s;
    }

    .reload-hide {
        opacity: 0;
    }

    .reset-btn {
        float: right;
    }

</style>