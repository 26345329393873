<template>

    <div>
        <div class="row">
            <div class="col-xs-10 col-xs-offset-1">
                <h1>Clothing & Footwear Size Charts</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-10 col-xs-offset-1 size-chart-panel">

                <p class="text-info">Please provide the following information to create a new size chart or to view an existing size chart. Please <router-link v-bind:to="{name : 'contact'}" data-ui="contact-link" class="contact-link">contact</router-link> us to update an existing size chart.</p>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="size-chart-filter">
                            <h1 class="h4">Enter your brand's name </h1>
                            <div class="form-control">
                                <autocomplete-input
                                    :suggestions="brandSuggestions"
                                    hide-label
                                    label=""
                                    placeholder="Brand name"
                                    @autocomplete-change="fetchGroups"
                                    data-ui="brand-name-field">
                                </autocomplete-input>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">

                        <h1 class="h4">Select a size chart category </h1>
                        <select class="form-control"
                                data-ui="size-chart-category-dropdown"                        
                                v-model="selectedGroupId"
                                @change="groupSelected">
                            <option disabled selected value class="form-control" id="default-group">Size chart category</option>
                            <option v-for="group in groups" :key="group.groupId" :value="group.groupId">{{ group.displayName }}</option>
                        </select>
                    </div>
                </div>
				<div class="row" v-show="showStyleIdInput">
					<div class="col-sm-6">
						<h1 class="h4">Enter the 6-character product number, or a list of product numbers separated by commas:</h1>
						<input type="text" v-model="styleIds" class="form-control"
							placeholder="Product number" data-ui="style-id-field"/>
					</div>
				</div>
				<div class="row" v-show="showStyleIdInput">
					<div class="col-xs-4 col-sm-2">
						<input type="button" value="Get chart" class="form-control chart-btn" @click="validateAndFetchChart" data-ui="get-chart-button"/>	
					</div>
				</div>
			</div>
        </div>

        <size-chart-table :chart="chart"
                          :loading="loading"
                          :updating="updating"
                          :chart-header="chartHeader"
                          @update-chart="updateChart"></size-chart-table>

    </div>

</template>

<script>

    import AutocompleteInput from '@rei/autocomplete'
    import SizeChartTable from "./SizeChartTable.vue";

    export default {
        name: "size-chart",
        data() {
            return {
                selectedBrand: null,
                selectedGroupId: 0,
                styleIds: "",
                showStyleIdInput: false,
                brands: [],
                groups: [],
                chart: null,
                loading: false,
                updating: false,
                chartHeader: "Select your brand"
            }
        },
        components : {
            AutocompleteInput,
            SizeChartTable
        },
        computed : {
            filteredBrands() {
                return this.brands;
            },
            unauthorized() {
                return this.$store.state.unauthorized;
            },
            brandSuggestions() {
                return this.brands.map(brand => brand.name);
            }
        },
        methods: {
            fetchBrands() {

                this.loading = true;

                this.$http.get("rs/size/size-chart-brands")
                    .then(response => {
                        this.brands = response.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.addMessage("Error fetching brands");
                        this.loading = false;
                    });
            },
            fetchGroups(brandName) {

                this.selectedBrand = this.brands.find(brand => brand.name === brandName);
                this.chart = null;
                this.loading = true;
                this.showStyleIdInput = false;
                this.selectedGroupId = 0;

                if (!this.selectedBrand) return;

                this.$http.get(`rs/size/size-chart-groups/${this.selectedBrand.id}`)
                    .then(response => {
                        this.groups = response.data;
                        this.chartHeader = this.groups.length ? `Select a category to view size chart`  : `Size charts are not needed for ${brandName}, thanks!`;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.addMessage("Error fetching groups");
                        this.loading = false;
                    });
            },
            groupSelected() {
            	
            	this.styleIds = "";
            	this.chartHeader = "";
            	
            	//Show Style ID input to get additional information if style level group is selected.
            	//Otherwise, fetch the size chart.
            	this.showStyleIdInput = this.groups.find(g => g.groupId === this.selectedGroupId).styleLevelOnly;
            	
            	if (!this.showStyleIdInput) {
            		this.fetchChart()
				}
            },
            fetchChart() {
            	
                this.loading = true;
                
                this.$http.get("rs/size/size-chart", {params: {brandId: this.selectedBrand.id, groupId: this.selectedGroupId, styleIds: this.styleIds}})
                    .then(response => {
                        this.chart = response.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.addMessage("Error fetching size chart");
                        this.loading = false;
                    });
            },
            updateChart(chart) {

                this.updating = true;
                this.chart.rows = chart.rows;
                this.chart.header = chart.header;
                this.chart.notes = chart.notes;

                this.$http.put("rs/size/update-size-chart", this.chart)
                    .then(response => {
                        this.updating = false;
                        this.addMessage("Size chart update successful");
                        //fetch the newly created chart
                        this.fetchChart();
                    })
                    .catch(error => {
                        this.updating = false;
                        this.addMessage("Error saving size chart");
                    });
            },
            validateAndFetchChart() {
                
                if (this.styleIds) {
                	
                	const invalidMessage = "Incorrect product number entered: ";
                	                	       	
                	Promise.all(this.styleIds
                		.replace(/,/g, " ").split(" ")
               	        .filter(s => s.trim().length)
               	        .map(s => s.substring(0,6))
               	        .map(s => this.$http.get(`rs/product/style/${s}`)))
           	        .then(responses => {
           	        	
           	        	let valid = true;
           	        	
           	        	//display errors if styles are found but do not match the selected brand
           	        	responses.forEach(response => {
           	        		if (this.selectedBrand.id != response.data.brandId) {
           	        			this.addMessage(invalidMessage + response.data.style);
           	        			valid = false;
    						}
                        });
           	        	
           	        	if (!valid) {
           	        		//break promise chain
           	        		throw new Error();
						}
           	    	})
               	    .then(responses => {
               	   		//all promises are resolved, fetch size chart
               	    	this.fetchChart();
           	    	})
               	    .catch(error => {
               	    	if (error.response) {
							if (error.response.status === 401) {
	               	            this.addMessage(this.unauthorized);
	               	        } else {
	               	            this.addMessage(invalidMessage + error.response.data);
	               	        }
               	    	}
               	    });
                	
                }
                
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        },
        mounted() {
            this.fetchBrands();
        }
    }
</script>

<style lang="scss">

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    .size-chart-panel {
        background-color: $mainBgColor;
        font-family: $latoFontStack;
        font-size: 125%;
        margin-top: 20px;
        padding: 15px;
        @include box-shadow;
    }

    .autocomplete--clear {
        margin-bottom: 7px;
    }

    .contact-link {
        text-decoration: underline;
        font-weight: bold;
    }
    
    .chart-btn {
        margin-top: 10px;
        width: 100%;
        min-width: 90px;
        max-width: 150px;
        background-color: $adminOrange;
        color: white;
        font-weight: bold;
    }

    .size-chart-filter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .size-chart-filter > .form-control,
    .size-chart-filter > .autocomplete {
        width: 100%;
        height: 100%;
    }

    .size-chart-filter > .form-control {
        padding: 0em !important;
    }

    .size-chart-filter > .form-control,
    .size-chart-filter > div.form-control > div.autocomplete > div > div > div > div > input,
    .size-chart-filter > div.form-control > div.autocomplete > ul {
        font-family: Lato,Helvetica,Neue,Roboto,Arial,sans-serif !important;
        font-size: 1.3rem;
        color: #555 !important;
    }

    .size-chart-filter > div.form-control > div.autocomplete > div > div > div > div > input {
        height: fit-content;
        box-shadow: none;
        padding: 0.5rem;
    }

    .size-chart-filter > div.form-control > div.autocomplete > ul {
        font-size: 1.8rem !important;
    }

</style>