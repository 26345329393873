<template>
    <modal v-if="messages.length">
      <template v-slot:body>
        <div class="message-modal" data-ui="message-modal">

            <div class="row">
                <div class="col-xs-12">
                    <h2 class="modal-title">Alert</h2>
                    <hr />
                </div>
            </div>
            <div class="row message-container">
                <div class="col-xs-12">
                    <ul>
                        <li data-ui="modal-text" v-for="message in messages" :key="message" class="modal-text">
                            {{ message }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-10 col-xs-offset-1 modal-btns">
                    <input type="button" class="ib-btn" data-ui="close-modal-button" value="Close" @click="closeModal"/>
                </div>
            </div>

        </div>
      </template>
    </modal>
</template>

<script>

    import Modal from "./Modal.vue";

    export default {
        name: "MessageModal",
        props: ["showModal"],
        components: {
            Modal
        },
        computed: {
            messages() {
                return this.$store.state.messages;
            }
        },
        methods: {
            closeModal() {
                this.$store.commit("removeMessages", this.$store.state.messages.length);
                this.$emit("close-modal");
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    .message-modal {

        .modal-title {
            margin: 5px 10px;
        }

        hr {
            padding: 0;
            margin: 0;
            border: 1px solid lightgray;
        }

        .message-container {
            margin: 0;
            padding: 0 10px;
            width: 100%;
            max-height: 400px;
            overflow-y: auto;

            ul {
                padding-top: 10px;
            }

            .modal-text {
                font-family: $latoFontStack;
                padding: 10px 0;
                color: #424242;
            }
        }

        .modal-btns {
            text-align: center;
            padding: 10px 0;

            .ib-btn {
                float: none;
                width: 100px;
            }
        }
    }
</style>