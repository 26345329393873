let a = document.createElement("a");
document.body.appendChild(a);

export default (data, fileName) => {

    let blob = new Blob([data], {type: "octet/stream"});

    if (window.navigator.msSaveBlob) {

        //handle special case for Microsoft browsers
        window.navigator.msSaveBlob(blob, fileName);

    } else {

        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
};