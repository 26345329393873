<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 how-to">

            <h1>How To Instructions for Uploading Content</h1>

            <h2>Specs & Copy</h2>

            <div class="how-to-section">
                <p>1. Use the
                    <router-link v-bind:to="{name : routeNames.TEMPLATE_FINDER}">
                        Download Product Spreadsheets
                    </router-link>
                    link and select the category that best matches your product. Click the download button on the right when you've made your selection.
                </p>

                <img :src="howTo1" />

                <p>2. Open the spreadsheet on your computer. Paste in the list of REI 6-character style and details from our content requests emails. Enter the specs and copy.</p>

                <ul>
                    <li>Keep in mind that this information will be seen on REI.com</li>
                    <li>Our writers and editors may tweak your submitted content to match our standards. The more details we have to work with the better!</li>
                    <li>Marketing copy will be displayed under the product imagery on REI.com, and is critical in the explanation of a product's features for our customers. Please clearly list all features.</li>
                    <li>It is extremely important to provide complete and accurate specs. Specs drive the navigation on REI.com. Without complete specs customers are less likely to find your product.</li>
                    <li>When there are specification differences between sizes that would be of interest to a customer please enter the product on multiples rows, with the size included in the Marketing Title.</li>
                    <li>
                        For example, customers are interested in the head circumference and weight differences between helmet sizes. The REI six-character would be entered on multiple rows, with the size included in the Marketing Title.
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Vendor Item #</th>
                                    <th>REI Item #</th>
                                    <th>Brand</th>
                                    <th>Marketing Title</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>ABK32</td>
                                    <td>775777</td>
                                    <td>REI</td>
                                    <td>Youth Kids Helmet - XS</td>
                                </tr>
                                <tr>
                                    <td>ABK32</td>
                                    <td>775777</td>
                                    <td>REI</td>
                                    <td>Youth Kids Helmet - S</td>
                                </tr>
                                <tr>
                                    <td>ABK32</td>
                                    <td>775777</td>
                                    <td>REI</td>
                                    <td>Youth Kids Helmet - M</td>
                                </tr>
                                <tr>
                                    <td>ABK32</td>
                                    <td>775777</td>
                                    <td>REI</td>
                                    <td>Youth Kids Helmet - L</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                </ul>


                <p>3. When you’re ready to upload your spreadsheet, save it on your computer and use the
                    <router-link v-bind:to="{name : routeNames.UPLOAD, params: { type: 'pi' }}">
                        Upload Product Spreadsheets
                    </router-link>
                    link.
                </p>

                <img :src="howTo2" />

                <p>4. Fill in the Name, Email, and Brand fields. Drag and drop your spreadsheet into the blue box if your browser supports it, or use the file chooser to select the files.</p>

                <ul>
                    <li>You can only upload files after filling in the required Name, Email, and Brand fields. The optional Notes field can be used to provide us with additional information about the upload.</li>
                    <li>If you’re experiencing issues uploading, the first step is to update your browser to the latest version. You may need to try a different browser. Chrome and Firefox tend to be the best options.</li>
                </ul>

                <p>5. When a file has a status of "Uploaded", the upload is complete and you can safely navigate away from the page. Thank you!</p>

                <img :src="howTo5" />
            </div>


            <h2>Images</h2>
            <div class="how-to-section">
                <p>1. Enter the 10-character REI SKU at the front of image filenames. This assigns the image to the correct product on REI.com</p>

                <p>2. Use the
                    <router-link v-bind:to="{name : routeNames.UPLOAD, params: { type: 'image' }}">
                        Upload Product Images
                    </router-link>
                    link
                </p>

                <img :src="howTo3" />

                <p>3. Fill in the Name and Email fields. Drag and drop your images into the blue box if your browser supports it, or use the file chooser to select the images.</p>

                <ul>
                    <li>You can only upload files after filling in the required Name and Email fields. The optional Notes field can be used to provide us with additional information about the upload.</li>
                    <li>If you’re experiencing issues uploading the first step is to update your browser to the latest version. You may need to try a different browser, Chrome, Firefox, and Safari tend to have the least issues.</li>
                </ul>

                <p>4. When a file has a status of "Uploaded", the upload is complete and you can safely navigate away from the page. If uploading a large amount of images, please be sure to scroll through the list to confirm there were no issues with any images. Thank you!</p>

                <img :src="howTo4" />
            </div>

        </div>
    </div>

</template>

<script>

    import howTo1 from "../../resources/static/img/how-to-1.jpg";
    import howTo2 from "../../resources/static/img/how-to-2.jpg";
    import howTo3 from "../../resources/static/img/how-to-3.jpg";
    import howTo4 from "../../resources/static/img/how-to-4.jpg";
    import howTo5 from "../../resources/static/img/how-to-5.jpg";
    import routeNames from "../data/routeNames";


    export default {
        name: "how-to",
        data() {
            return {
                howTo1,
                howTo2,
                howTo3,
                howTo4,
                howTo5,
                routeNames
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../scss/base/vars";
    @import "../../scss/base/mixins";

    .how-to {

        .how-to-section {
            background-color: $mainBgColor;
            padding: 15px;
            @include box-shadow;

            &:hover {
                @include box-shadow-hover;
            }

            p {
                font-size: 120%;
                font-family: $verandaFontStack;
                margin: 30px 0 30px 15px;
            }

            img {
                margin-left: 3%;
                width: 95%;
                max-width: 1000px;
                @include containerBorder;
                @include box-shadow;
            }

            ul {
                font-weight: bold;
                font-size: 100%;
                margin-left: 45px;
                font-family: $verandaFontStack;

                li {
                    margin-bottom: 5px;
                }
            }

            table {
                font-family: 'Lato', Helvetica, Neue, Roboto, Arial, sans-serif;
                margin-top: 20px;
                font-size: 110%;
                width: 600px;
                @include box-shadow;

                thead {
                    background-color: $mainOffsetColor;
                }

                tbody {
                    background-color: white;
                }
            }
        }
    }

</style>