<template>
    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row">
                <div class="col-xs-12">
                    <h3 class="title">Archived Assets</h3>
                    <p class="text-info">Assets are moved to the archive after download, and deleted after a month.</p>
                </div>
            </div>

            <div class="row term-container">
                <div class="col-xs-10 col-sm-6">
                    <input type="text" v-model="filterTerm" class="form-control" placeholder="Filter by vendor name, email, brand, filename or style number"/>
                </div>
                <div class="col-xs-1" >
                    <img title="refresh" :class="{'reload-image': true, 'spin-image' : loadingData }" @click="fetchData" :src="reloadImg" width="22" height="17">
                </div>
            </div>

            <div class="row btn-container">
                <div class="col-sm-2 col-sm-offset-1">
                    <input type="button" :class="btnClass(filterType.PI)" value="PI" @click="filterByType(filterType.PI)"/>
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.IMAGE)" value="Images" @click="filterByType(filterType.IMAGE)"/>
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.VIDEO)" value="Videos" @click="filterByType(filterType.VIDEO)"/>
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.LOGO)" value="Logos" @click="filterByType(filterType.LOGO)"/>
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.PDF)" value="PDF" @click="filterByType(filterType.PDF)"/>
                </div>
            </div>

            <div class="row">

                <div v-if="loadingData" class="col-xs-12">
                    <h3>Loading...</h3>
                </div>

                <div v-else-if="!filtered.length" class="col-xs-12">
                    <h3>No Results</h3>
                </div>

                <div v-else class="col-xs-12 table-container">
                    <table class="table table-responsive">
                        <thead>
                        <tr>
                            <th>Archive Date</th>
                            <th>Brand</th>
                            <th>Filename - Click to Download</th>
                            <th>Name</th>
                            <th v-if="selectedType.name === filterType.VIDEO.name">YouTube</th>
                            <th>Email</th>
                            <th v-if="selectedType.name === filterType.VIDEO.name || selectedType.name === filterType.PDF.name">Style</th>
                            <th>Processed By</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="asset in filtered" :key="asset.id" :class="{'failed-upload' : asset.uploadDto.status === 'Failed'}">
                            <td>{{ asset.archiveDate }}</td>
                            <td>{{ asset.uploadDto.brand }}</td>
                            <td class="download-file" @click="download(asset)">{{ asset.uploadDto.filename }}</td>
                            <td>{{ asset.uploadDto.name }}</td>
                            <th v-if="selectedType.name === filterType.VIDEO.name"><a v-if="asset.youTubeUrl" :href="asset.youTubeUrl" target="_blank">View</a></th>
                            <td><a :href="'mailto:' + asset.uploadDto.email">{{ asset.uploadDto.email }}</a></td>
                            <td v-if="selectedType.name === filterType.VIDEO.name || selectedType.name === filterType.PDF.name">{{ asset.uploadDto.styleId }}</td>
                            <td>{{ asset.downloadedBy }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import reloadImg from "../../../resources/static/img/reload.png";
    import saveFile from "../../util/saveFile";
    import formatDate from "../../util/formatDate";
    import types from "../../data/UploadTypes";

    export default {
        name: "archived-assets",
        data() {
            return {
                assets: [],
                filterType: types,
                selectedType: types.PI,
                filterTerm: "",
                loadingData: true,
                reloadImg
            }
        },
        computed: {
            filtered() {
                let filterList = this.assets;
                const mapItem = (item) => {
                    return {
                        ...item,
                        archiveDate: formatDate(item.archiveDate),
                    };
                };
                if (this.filterTerm) {
                    filterList = filterList
                        .filter(asset => asset.filterTerm.includes(this.filterTerm.toLowerCase()))
                        .map(mapItem);
                }
                if (this.selectedType) {
                    filterList = filterList
                        .filter(asset => asset.uploadDto.type.includes(this.selectedType.name))
                        .map(mapItem);
                }
                return filterList;
            }
        },
        methods: {
            btnClass(type) {
                return {
                    'form-control' : true,
                    'admin-btn' : true,
                    'btn-selected' : this.selectedType.name === type.name
                }
            },
            filterByType(type) {
                this.selectedType = type;
            },
            download(asset) {

                const fp = asset.filepath;

                this.$http({
                    url: `rs/asset/archive/${asset.id}`,
                    method: "GET",
                    responseType: "blob"
                }).then(response => {
                    const filename = fp.substring(fp.lastIndexOf("/") + 1);
                    saveFile(response.data, filename);
                }).catch(error => {
                    this.addMessage(`Error downloading archived asset ${fp}`);
                });
            },
            fetchData() {

                this.loadingData = true;

                this.$http.get("rs/asset/data/archived")
                    .then(response => {

                        this.assets = response.data;

                        //add the filter term
                        this.assets.forEach(asset => {
                            asset.filterTerm = `${asset.uploadDto.name}${asset.uploadDto.brand}${asset.uploadDto.filename}${asset.uploadDto.email}${asset.uploadDto.styleId}`.toLowerCase();
                        });

                        this.loadingData = false;

                    }).catch(error => {

                    this.addMessage("Error fetching asset uploaded data");
                    this.loadingData = false;
                });
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        },
        mounted() {
            this.fetchData();
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    .term-container {
        margin-top: 20px;
    }

    .btn-container {
        margin: 20px 0;

        .admin-btn {
            margin: 0 auto;

            @media (max-width: 768px) {
                margin-bottom: 10px;
            }
        }

        .btn-selected {
            color: black;
            background-color: #ffa450;
        }
    }

    .table-container {
        height: auto;
        max-height: 800px;
        overflow: auto;

        table {
            display: block;

            thead {
                background-color: $mainOffsetColor;
                font-weight: bold;

                th {
                    height: 50px;
                }
            }

            tbody {
                background-color: white;

                .download-file {

                    color: $adminBlue;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

</style>