import Login from "../components/Login.vue";
import Home from "../components/home/Home.vue";
import Upload from "../components/upload/Upload.vue";
import TemplateFinder from "../components/TemplateFinder.vue";
import SkuFinder from "../components/SkuFinder.vue";
import Faq from "../components/Faq.vue";
import HowTo from "../components/HowTo.vue";
import Contact from "../components/contact/Contact.vue";
import Admin from "../components/admin/Admin.vue";
import SizeChart from "../components/sizechart/SizeChart.vue";
import CloneRequest from "../components/clone/CloneRequest.vue";
import ProductInfo from "../components/contact/ProductInfo.vue";
import routeNames from "../data/routeNames";
import { createRouter, createWebHashHistory } from "vue-router";

const appRoutes = [
    { path: "/", component: Login, name: routeNames.LOGIN},
    { path: "/home", component: Home, name: routeNames.HOME},
    { path: "/upload/:type", component: Upload, name: routeNames.UPLOAD},
    { path: "/template-finder", component: TemplateFinder, name: routeNames.TEMPLATE_FINDER},
    { path: "/sku-finder", component: SkuFinder, name: routeNames.SKU_FINDER},
    { path: "/faq", component: Faq, name: routeNames.FAQ},
    { path: "/how-to", component: HowTo, name: routeNames.HOW_TO},
    { path: "/contact", component: Contact, name: routeNames.CONTACT},
    { path: "/admin", component: Admin, name: routeNames.ADMIN},
    { path: "/size-chart", component: SizeChart, name: routeNames.SIZE_CHART},
    { path: "/clone-request", component: CloneRequest, name: routeNames.CLONE_REQUEST},
    { path: "/product-info", component: ProductInfo, name: routeNames.PRODUCT_INFO},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: appRoutes,
});

export default router;

