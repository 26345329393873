<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row download-controls">
                <div class="col-xs-5 col-sm-6">
                    <h3 class="title">Download Assets</h3>
                    <p class="text-info">Download assets by category or by asset</p>
                </div>
                <div class="col-xs-1 col-xs-offset-2 col-sm-offset-3">
                    <img title="refresh" :class="{'reload-image': true, 'spin-image' : loadingAssets }" @click="fetchData" :src="reloadImg" width="22" height="17">
                </div>
                <div class="col-xs-4 col-sm-2">
                    <input type="button" @click="downloadCategory" class="form-control admin-btn" value="Download" :disabled="downloading"/>
                </div>
            </div>

            <div class="row download-table">
                <div class="col-xs-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Select All</th>
                                <th>List Assets</th>
                                <th>Asset Category</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="cat in categories">
                                <tr :key="cat.name" v-bind:class="{'selected-row' : cat.selected}" v-if="cat.assets.length">
                                    <td><input v-on:click="selectRow(cat)" type="radio" name="type-select" :disabled="downloading"/></td>
                                    <td><input v-on:click="selectSingle(cat)" type="radio" name="single-select" :disabled="downloading"/></td>
                                    <td class="asset-type">{{ cat.name }}</td>
                                    <td>{{ cat.assetTotal }}</td>
                                    <td><img :class="{'reload-image': true, 'spin-image': cat.downloading, 'display-image': !cat.downloading }"
                                             :src="reloadImg" width="22" height="17">
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="singleAssetList.length" class="row download-table single-table">
                <div class="col-xs-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Upload Date</th>
                            <th>Brand</th>
                            <th>Notes</th>
                            <th>Filename - Click to Download</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="asset in singleAssetListFormatted" :key="asset.id">
                            <td class="download-date">{{ asset.uploadDto.uploadDate }}</td>
                            <td>{{ asset.uploadDto.brand }}</td>
                            <td>{{ asset.uploadDto.notes }}</td>
                            <td class="download-file" @click="downloadSingle(asset)">{{ asset.uploadDto.filename }}</td>
                            <td><img :class="{'reload-image': true, 'spin-image': asset.downloading, 'display-image': !asset.downloading }"
                                     :src="reloadImg" width="22" height="17">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

    import saveFile from "../../util/saveFile";
    import reloadImg from "../../../resources/static/img/reload.png";
    import formatDate from "../../util/formatDate";

    export default {
        name: "download-assets",
        data() {
            return {
                categories: {},
                selected: null,
                loadingAssets: false,
                singleAssetCategory: null,
                singleAssetList: [],
                reloadImg,
                downloadIndex: 0,
                downloading: false,
                downloadStatus: {
                    DOWNLOADED   : "Downloaded",
                    FAILED     : "Failed"
                }
            }
        },
        computed: {
            singleAssetListFormatted() {
                return this.singleAssetList.map((asset) => {
                    const uploadDto = {
                        ...asset.uploadDto,
                        uploadDate: formatDate(asset.uploadDto.uploadDate),
                    };
                    return {
                        ...asset,
                        uploadDto,
                    };
                });
            }
        },
        methods: {
            selectRow(category) {

                this.selected = category;

                //remove any selected rows
                for (let cat in this.categories) {
                    this.categories[cat].selected = false;
                }

                //set selected for css styling
                category.selected = true;
            },
            selectSingle(category) {
            	this.singleAssetCategory = category;
                this.singleAssetList = category.assets;
            },
            downloadCategory() {

                if (!this.selected) {
                    this.addMessage("Please select a category");
                    return;
                }
                
                let downloadedAll = this.downloadIndex === this.selected.assets.length;
                
                if (downloadedAll) {
                	this.downloading = false;
                	this.downloadIndex = 0;
                	this.clearDownloaded();
				}  else {
					
                	let asset = this.selected.assets[this.downloadIndex];

                	this.downloading = true;
                	this.selected.downloading = true;
                    const fp = asset.filepath;

                    this.$http({
                        url: `rs/asset/${asset.id}`,
                        method: "GET",
                        responseType: "blob"
                    }).then(response => {

                        const filename = fp.substring(fp.lastIndexOf("/") + 1);
                        saveFile(response.data, filename);
                        
                        this.archive(asset);
                        
                        asset.status = this.downloadStatus.DOWNLOADED;
                        this.selected.assetTotal--;
                        this.nextCategoryDownload();

                    }).catch(error => {
                    	
                    	let message = `Error downloading asset ${fp}`;
                    	let username = this.$store.state.username;
                    	
                    	this.$http.get(`rs/asset/downloadedBy/${asset.id}`)
		                    .then(response => {
		                        if (response.data && response.data !== username) {
		                        	message += " Already downloaded by: " + response.data;
		                        	
		                        	//asset not found due to other user downloading at same time
		                            //remove asset from category list
		                        	asset.status = this.downloadStatus.DOWNLOADED;
		                        	this.selected.assetTotal--;
		                        } 
	                        	this.addMessage(message);
	                        	this.selected.downloading = false;
		                    });
                    	
                    	asset.status = this.downloadStatus.FAILED;
                    	this.nextCategoryDownload();
                    });
                }
            },
            nextCategoryDownload() {
                this.downloadIndex++;
                //small timeout is needed to not occasionally overwrite a download
                setTimeout(this.downloadCategory, 500);
            },
            downloadSingle(asset) {
                if (asset.downloading) {
                    return;
                }

                asset.downloading = true;
                const fp = asset.filepath;

                //get a reference to the list in case the user switches to a different list before download completes
                const assetList = this.singleAssetList;
                
                this.$http({
                    url: `rs/asset/${asset.id}`,
                    method: "GET",
                    responseType: "blob"
                }).then(response => {
                    const filename = fp.substring(fp.lastIndexOf("/") + 1);
                    saveFile(response.data, filename);
                    asset.downloading = false;
                    assetList.splice(assetList.indexOf(asset), 1);
                    this.singleAssetCategory.assetTotal--;
                    this.archive(asset);
                }).catch(error => {
                    asset.downloading = false;
                    this.addMessage(`Error downloading asset ${fp}`);
                });
            },
            clearDownloaded() {
                this.selected.assets = this.selected.assets.filter(asset => asset.status !== this.downloadStatus.DOWNLOADED);
                this.selectSingle(this.selected);
            },
            archive(asset) {
                this.$http.delete(`rs/asset/${asset.id}`)
                    .then(response => {})
                    .catch(error => {});
            },
            initPaths(assets) {

                //separate assets into categories
                this.categories = assets.reduce((obj, asset) => {

                    const type = asset.filepath.substring(0, asset.filepath.lastIndexOf("/"))
                        .replace("asset/", "")
                        .toUpperCase();

                    asset.downloading = false;

                    //videos are auto-uploaded to youtube, users don't need to download them
                    //PDFs are displayed on a separate admin panel for upload to media tool, users don't need to download them
                    if (/*type !== "VIDEO" && */type !== "PDF") {
	                    if (obj[type]) {
	                        obj[type].total++;
	                        obj[type].assets.push(asset);
	                        obj[type].assetTotal++;
	                    } else {
	                        obj[type] = {
	                            name: type,
	                            assets: [asset],
	                            selected: false,
	                            displayAssets: false,
	                            downloading: false,
	                            assetTotal: 1
	                        };
	                    }
                    }
                    return obj;
                }, {});
            },
            fetchData() {

                this.loadingAssets = true;

                this.$http.get("rs/asset/data")
                    .then(response => {

                        if (response.data && response.data.length) {
                            this.initPaths(response.data);
                        } else {
                            this.addMessage("No new assets");
                        }
                        this.loadingAssets = false;

                    }).catch(error => {

                    if (error.response.status === 401) {
                        //redundant auth check, non admin's shouldn't be able to get here
                        //but just in case redirect to login page
                        this.$router.push("/");
                    } else {
                        this.addMessage("Error fetching uploaded assets");
                        this.loadingAssets = false;
                    }
                });
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        },
        mounted() {
            this.fetchData();
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .admin-panel {
        min-height: 600px;

        .download-controls {

            margin-bottom: 10px;

            .reload-image {
                margin-top: 20px;
                float: right;
            }

            .admin-btn {
                float: right;
            }
        }

        table {

            thead {
                background-color: $mainOffsetColor;
                font-weight: bold;

                th {
                    height: 50px;
                }
            }

            tbody {
                background-color: white;

                tr {
                    border-left: 1px solid $mainBgColor;
                    border-right: 1px solid $mainBgColor;

                    td {
                        padding-top: 10px;
                        padding-bottom: 10px;

                        input[type="radio"] {
                            margin-top: 0;
                            padding-bottom: 5px;
                            outline: none;
                            box-shadow:none;
                        }
                    }
                }

                .download-date {
                    min-width: 210px;
                }

                .download-file {

                    color: $adminBlue;
                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                .selected-row {
                    border-left: 1px solid $adminBlue;
                    border-right: 1px solid $adminBlue;
                }

                .selected-row td {
                    background-color: $adminLightBlue;
                    border-bottom: 1px solid $adminBlue;
                    border-top: 1px solid $adminBlue;
                }

                .selected-row:hover td {
                    background-color: $adminLightBlue;
                }

                .asset-type {
                    color: $adminBlue;
                    padding-top: 14px;

                }
            }
        }
    }

    .single-table {
        height: auto;
        max-height: 500px;
        overflow: auto;
    }

</style>