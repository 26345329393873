export default Object.freeze({
    ADMIN: "admin",
    HOME: "home",
    LOGIN: "login",
    UPLOAD: "upload",
    TEMPLATE_FINDER: "template-finder",
    SKU_FINDER: "sku-finder",
    FAQ: "faq",
    HOW_TO: "how-to",
    CONTACT: "contact",
    SIZE_CHART: "size-chart",
    CLONE_REQUEST: "clone-request",
    PRODUCT_INFO: "product-info"
});