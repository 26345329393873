<template>

    <div class="row">
        <div v-if="isFooterPage" class="col-xs-10 col-xs-offset-1 footer-content">
            <p class="disclaimer-message">Vendors are responsible for ensuring that all materials now and previously
                provided to REI related to products, including product information, product description, images,
                packaging, sound recordings, art, photographs or pictures, videos, and/or text, are current,
                accurately reflect the product for sale, and comply with any and all applicable federal, state and local
                laws, regulations, orders, and ordinances. REI is not responsible for the accuracy, completeness,
                safety, and legal compliance of all, or any portion, of the products and materials related to the
                products. Unless instructed by Vendors, REI is not responsible for updating Vendor-provided materials to
                ensure compliance with any current federal, state, and local laws and regulations.</p>
        </div>
        <div v-if="isImageUploadPage" data-ui="update-message" class="col-xs-10 col-xs-offset-1 footer-content">
          <p class="update-message">If your product or product packaging has changed, please submit updated images through this image uploader.</p>
        </div>
        <div v-if="isFooterPage" class="col-xs-10 col-xs-offset-1 footer-content">
            <span>We're here to help!</span> <router-link v-bind:to="{name : routeNames.CONTACT}" data-ui="contact-link">Contact</router-link> us with any questions.
        </div>
        <div v-if="isFooterPage && isBrowserIE" data-ui="browser-message" class="col-xs-10 col-xs-offset-1 footer-content browser-message">
            We recommend using Chrome, Firefox, or Safari to take advantage of the features of this website. Internet Explorer is not supported.
        </div>
    </div>

</template>

<script>

    import routeNames from '../../data/routeNames';

    //pages that don't get the footer
    const pages = [routeNames.LOGIN, routeNames.ADMIN, routeNames.CONTACT];
    
    export default {
        name: 'app-footer',
        data() {
            return {
                routeNames,
                isBrowserIE: false
            }
        },
        computed: {
            isFooterPage() {
                return !pages.includes(this.$route.name);
            },
            isImageUploadPage() {
                return this.$route.name === routeNames.UPLOAD && this.$route.params.type === "image";
            }
        },
        mounted() {
	     	// This is internet explorer 11 or Edge
	   		if (/rv:11.0/i.test(navigator.userAgent) || /Edge\/\d./i.test(navigator.userAgent)) {
	   			this.isBrowserIE = true;
	   		}
        }
    }
</script>

<style lang="scss" scoped>

    .footer-content {
        float: left;
        margin-top: 10px;
        text-align: center;
        font-family: verdana, sans-serif;
    }

    .footer-content span {
        font-weight: bold;
        color:  #333333;
    }
    
    .footer-content a {
        font-weight: bold;
        color:  #0000EE;
    }
    
    .browser-message {
        margin-top: 5px;
        font-style: italic;
        font-size: small;
    }

    .disclaimer-message {
        font-style: italic;
        font-size: x-small;
        text-align: left;
    }

    .update-message {
        font-size: large;
    }

</style>