<template>
  <div>
    <div class="row">
      <div class="col-sm-10 col-sm-offset-1">
        <h2>Enhancing the customer experience through outstanding content</h2>
      </div>
    </div>

    <div class="row">
      <div
        class="col-sm-10 col-sm-offset-1 login-content"
        :style="{ backgroundImage: runBg }"
      >
        <div class="row">
          <form
            class="col-xs-4 col-xs-offset-2 col-md-3 login-form"
            action="index.php"
            method="post"
          >
            <!--[if IE]><span>Username</span><![endif]-->
            <div>
              <input
                class="form-control text-input"
                v-model="username"
                @keyup.enter="login"
                type="text"
                data-ui="username-field"
                name="user"
                placeholder="Username"
              />
            </div>
            <!--[if IE]><span>Password</span><![endif]-->
            <div>
              <input
                class="form-control text-input"
                v-model="password"
                @keyup.enter="login"
                type="password"
                data-ui="password-field"
                name="pass"
                placeholder="Password"
              />
            </div>
            <input
              class="btn-primary-active"
              type="button"
              data-ui="log-in-button"
              @click="login"
              value="Log in"
            />
            <span v-if="loginError" data-ui="invalid-credentials-error" id="error"
              >Invalid Credentials</span
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import runBg from '../../resources/static/img/run-bg.jpg';
import routeNames from '../data/routeNames';

export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      loginError: false,
      runBg: this.createCssUrl(runBg),
    };
  },
  methods: {
    login() {
      this.loginError = false;
      let creds = {
        username: this.username,
        password: this.password,
      };

      this.$http
        .post('/rs/auth', creds)
        .then((response) => {
          this.$store.commit('setUsername', this.username);

          const role = response.data;
          this.$store.commit('setRole', role);

          //reset username and password so they aren't there when logged out
          this.username = '';
          this.password = '';

          if (role === 'admin') {
            this.$router.push({ name: routeNames.ADMIN });
          } else {
            this.$router.push({ name: routeNames.HOME });
          }
        })
        .catch((error) => {
          this.loginError = true;
        });
    },
    createCssUrl(image) {
      return 'url(' + image + ')';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/base/vars';
@import '../../scss/base/mixins';

.login-content {
  height: 460px;
  //background-image: url('../../img/run-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  border-radius: 3px;
  @include box-shadow;
}

.login-form {
  margin-top: 200px;
}

.text-input {
  margin-bottom: 20px;
  background-color: rgba(200, 200, 200, 0.6);
  color: white;
  border: 2px solid white;
}

.text-input::placeholder {
  color: #dadada;
}

/* do not group these rules */
*::-webkit-input-placeholder {
  color: #dadada;
}
*:-moz-placeholder {
  /* FF 4-18 */
  color: #dadada;
}
*::-moz-placeholder {
  /* FF 19+ */
  color: #dadada;
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color: #dadada;
}

.btn-primary-active {
  width: 25%;
  min-width: 50px;
  font-size: 120%;
  color: #ffffff;
  text-decoration: none;
  background-color: #d04b10;
  border: 1px solid #d04b10;
  border-radius: 1px;
  margin-right: 20px;
  display: inline;
  float: left;
  background-image: -webkit-linear-gradient(top, #e36421, #d04b10);
  background-image: -moz-linear-gradient(top, #e36421, #d04b10);
  background-image: -ms-linear-gradient(top, #e36421, #d04b10);
  background-image: -o-linear-gradient(top, #e36421, #d04b10);
  background-image: linear-gradient(to bottom, #e36421, #d04b10);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#e36421, endColorstr=#d04b10);
}

#error {
  color: red;
  font-size: 150%;
  float: left;
}
</style>
