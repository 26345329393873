/* Strip out unwanted characters from file names.
   Allow:
	   Letters A through Z (upper or lowercase)
	   Numbers 0 through 9
	   Dash "-"
	   Underscore "_"
	   Space " "
	   Dot "."
 */
export default(fileName) => {
    return fileName.replace(/[^A-Za-z0-9-_.\s]/g, "");
};