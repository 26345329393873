import piImage from "../../resources/static/img/pi.gif";

export default {
    title: "Specs & Copy",
    subTitle: "Download and upload templates for all ordered products",
    image: piImage,
    links: [
        {
            title: "Download Product Spreadsheets",
            to: {name : 'template-finder'}
        },
        {
            title: "Upload Product Spreadsheets",
            to: {name : 'upload', params: { type: 'pi' }}
        },
        {
            title: "Size Charts",
            to: {name : 'size-chart'}
        },
        {
            title: "Vendor Clone Request",
            to: {name : 'clone-request'}
        },
        {
            title: "Update Existing Product Information",
            to: {name : 'product-info'}
        }
    ]
};