<template>
  <app-header></app-header>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
  <app-footer></app-footer>
</template>

<script>
import AppHeader from '../../components/elements/AppHeader.vue';
import AppFooter from '../../components/elements/AppFooter.vue';

export default {
  name: 'LoginPageComponent',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>