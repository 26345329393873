<template>

	<div>
	
		<div class="row">
			<div class="col-xs-10 col-xs-offset-1">
				<h1>Clone Content from Old to New Product</h1>
			</div>
		</div>
	
		<div class="row">
			<div class="col-xs-8 col-xs-offset-1">
				<h3>
					<b>Please enter old and new styles below. Click New Clone to
						enter another set of New/Old styles.</b>
				</h3>
			</div>
      <div class="col-xs-1 col-xs-offset-1">
        <img title="reload" class="reload-image" :class="{'spin-image' : loading, 'reload-hide' : !loading }" :src="reloadImg"/>
      </div>
		</div>
	
		<div class="row">
			<div class="col-xs-10 col-xs-offset-1">
				<hr />
			</div>
		</div>
	
		<div class="row clone-input">
			<div class="col-xs-12 col-xs-offset-1 col-sm-1">
				<h2>Clones</h2>
			</div>
			<div class="col-xs-6 col-sm-2 col-xs-offset-1 col-sm-offset-1  ">
				<input type="text" data-ui="name-field" v-model="name" class="form-control email-inputs"
					v-bind:class="{ 'input-border' : !name }" placeholder="Your Name">
			</div>
			<div class="col-xs-6 col-sm-2 col-xs-offset-1 col-sm-offset-0 ">
				<input type="text" data-ui="email-field" v-model="email" class="form-control email-inputs"
					v-bind:class="{ 'input-border' : !email }"
					placeholder="Your Email Address">
			</div>
			<div class="col-xs-6 col-xs-offset-1 col-sm-offset-0 col-sm-2">
				<input v-on:click="addRow"
					class="form-control addrow-button email-inputs" type="button"
					value="New Clone" data-ui="addrow-button">
			</div>
			<div class="col-xs-6 col-xs-offset-1 col-sm-offset-0 col-sm-2">
				<input v-on:click="submit"
					class="form-control submit-button email-inputs" type="button"
					value="Submit" data-ui="submit-button">
			</div>
		</div>
	
		<div class="col-xs-10 col-xs-offset-1 row panel">
	
			<div class="col-xs-10 col-xs-offset-1 table-container">
	
				<table class="table table-responsive" v-for="(row, index) in rows">
					<tbody>
						<tr>
							<td><b>New 6-Character Style Number</b></td>
							<td><input type="text" :data-ui="'row-new-style-field-' + index" v-model="row.newStyle"
								class="form-control" v-on:keyup="styleKeyup(row, 'newStyle', $event)" maxlength="6"/></td>
							
							<td class="style-lookup-text"><input type="text"
								v-model="row.newStyleLookup" class="form-control"
								placeholder="<Brand> <Product Name>" readonly tabindex="-1"></td>
						</tr>
						<tr>
							<td><b>Old 6-Character Style Number</b></td>
							<td><input type="text" :data-ui="'row-old-style-field-' + index" v-model="row.oldStyle"
								class="form-control" v-on:keyup="styleKeyup(row, 'oldStyle', $event)" maxlength="6"/></td>
	
							<td><input type="text" v-model="row.oldStyleLookup"
								class="form-control" placeholder="<Brand> <Product Name>"
								readonly tabindex="-1"></td>
						</tr>
						<tr>
							<td><b>Notes</b></td>
							<td colspan="2"><input type="text" :data-ui="'row-notes-field-' + index" v-model="row.notes"
								class="form-control" /></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	
	</div>

</template>

<script>

	import Util from "../../util/util";
	import reloadImg from "../../../resources/static/img/reload.png";

    export default {
        name: "clone-request",
        data() {
            return {
            	rows: [],
                reloadImg,
                loading: false
            }
        },
        created() {
        	this.rows.push({});
        },
        computed : {
        	name: {
                get() {
                    return this.$store.state.name;
                },
                set(value) {
                    this.$store.commit("setName", value);
                }
            },
            email: {
                get() {
                    return this.$store.state.email;
                },
                set(value) {
                    this.$store.commit("setEmail", value);
                }
            }
        },
        methods: {
        	addRow() {
        		this.rows.push({});
       	    },
       	 	submit() {
       	    	let errorMessage = this.validateInputs();
                if (errorMessage) {
                    this.addMessage(errorMessage);
                    return;
                }
                
                let cloneRequest = {
                        name: this.name,
                        email: this.email,
                        details: this.rows
                };
                
                this.loading = true;
                
       	    	this.$http.post("/rs/clone", cloneRequest)
                .then(response => {
                		this.addMessage('Clone request submitted. Thanks!');
                		this.loading = false;
                    },
                    response => {
                    	this.addMessage('Submit Failed.');
                    	this.loading = false;
                    }
                )
       	    	
       	    },
            addMessage(message) {
               this.$store.commit("addMessage", message);
            },
            styleKeyup(row, styleType, event) {
            	
            	//Left arrow, Right arrow, Enter, Shift
            	if (event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 13 || event.keyCode == 16) { 
             	   return; 
             	}
            	
            	const style = row[styleType];
	            if (typeof style !== 'undefined' && style.length === 6) {
	                this.$http.get(`/rs/product/style/${style}`)
	                    .then(response => {
	                        if (response.data) {
	                        	let product = response.data; 
	                        	row[styleType + 'Lookup'] = product.brandName + " " + product.cleanDescription;
	                        	this.rows.push();
	                        }
	                    }).catch(error => {
	                    	document.activeElement.blur();
	                    	row[styleType] = "";
	                    	this.addMessage('Article number not found.  Please enter a valid article number.');
                        }
                    );
	            } else {
	            	row[styleType + 'Lookup'] = "";
                	this.rows.push();
                }
            },
            validateInputs() {

                if (!this.name || !this.email) {
                    return "Please enter your name and email address";
                }

                if (!Util.validateEmail(this.email)) {
                    return "Please enter a valid email address";
                }

                // Verify that the new and old styles are both set to 6 characters
                let validStyle = true;
                const idxs = this.rows.forEach(row => {
                    if (!this.isValidStyleFormat(row.newStyle)) {
                        row.newStyle = "";
                        validStyle = false;
                    }
                    if (!this.isValidStyleFormat(row.oldStyle)) {
                        row.oldStyle = "";
                        validStyle = false;
                    }
                });

                if (!validStyle) {
                    return "Style numbers must contain six characters.";
                }
            },
            isValidStyleFormat(style) {
                return typeof style !== "undefined" && style.length === 6;
            }
        }
    }
</script>

<style lang="scss">

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";
    
    .panel {
        background-color: $mainBgColor;
        font-family: 'Lato', Helvetica, Neue, Roboto, Arial, sans-serif;
        font-size: 125%;
        margin-bottom: 15px;
        border-radius: 3px;
        @include box-shadow;

        .title {
            font-weight: bold;
        }
        
        .style-lookup-text {
            width: 500px;
         }

	    .table-container {
	    	
	    	margin-bottom: 20px;

		    table {
		        margin: auto;
  				width: 950px;
  				text-align: center;
  				margin-top: 20px;
		
		        tbody {
		            background-color: white;
		        }
		    }
	 	}
	 	
    }
    
    hr {
        border: 1px solid gray;
        margin: 0;
    }
    
    .clone-input {

        margin-bottom: 15px;

        .input-border {
            border: 3px solid $reiOrange;
        }

        .email-inputs {
            margin-top: 25px;
        }

        .addrow-button, .submit-button {
	    	@include reiButton;
	    }
    }

    .reload-image {
        margin-top: 27px;
        margin-right: 10px;
        float: right;
        opacity: 1;
        transition: opacity 0.1s;
    }

    .reload-hide {
        opacity: 0;
    }

</style>