export default {

    validateEmail(email) {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email.toLowerCase());
    },

    indexOfIgnoreCase(array, itemToSearch){
        return array.findIndex(item => itemToSearch.toLowerCase() === item.toLowerCase());
    },

    removeUnderscore(value){
        if (!value) return '';
        return value.match(/_/g) ? value.replace(/_/g, " ") : value;
    },

    removeXlsx(value) {
        return value.includes(".xlsx") ? value.replace(".xlsx", "") : value;
    }
};