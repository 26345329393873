export default Object.freeze({
    PI: { 
    	id: 1,
    	name: "PI"
    },
    VIDEO: { 
    	id: 2,
    	name: "VIDEO"
    },
    LOGO: { 
    	id: 3,
    	name: "LOGO"
    },
    IMAGE: { 
    	id: 4,
    	name: "IMAGE"
    },
    PDF: { 
    	id: 5,
    	name: "PDF"
    }
});
