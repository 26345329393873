<template>
    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 resource-container">

            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-5">
                    <h4 class="resource-label">{{ mutateTitle }}</h4>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-7">
                    <input type="file"
                           height="40"
                           data-ui="choose-files-button"
                           @change="processFiles($event.target.files)"
                           class="form-control resource-file">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    const EXT = "pdf";

    export default {
        name: "resource-file",
        props: ["title", "filename"],
        data() {
            return {
                titleCopy: this.title,
                mutateTitle: this.title
            }
        },
        methods: {
            processFiles(files) {

                let file = files[0];

                if (this.getExtension(file.name) !== EXT) {
                    this.addMessage("Error: Only PDFs can be resources");
                    return;
                }

                this.mutateTitle = "Uploading...";

                let formData = new FormData();
                formData.append("file" , file);

                //file is renamed to match specific resource

                this.$http.post(`rs/resource/upload/${this.filename}`, formData)
                    .then(response => {
                        this.addMessage("Resource upload successful");
                        this.mutateTitle = this.titleCopy;
                    })
                    .catch(error => {
                        this.mutateTitle = this.titleCopy;
                        this.addMessage("Error: Resource upload failed");
                    });
            },
            getExtension(filename){
                return filename.substring(filename.lastIndexOf(".", filename) + 1).toLowerCase();
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .resource-container {
        margin-top: 20px;
        background-color: $mainOffsetColor;
        padding: 5px;
        border-radius: 3px;
        border: 1px solid darken($mainOffsetColor, 10%);
        height: 100%;

        .resource-label {
            float: left;
            margin-top: 14px;
            font-weight: bold;
        }

        .resource-file {
            float: left;
            width: 100%;
            margin-top: 5px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    //firefox needs this to display file input with form-control properly
    @-moz-document url-prefix() {
        .resource-file {
            padding: 0;
        }
    }

</style>