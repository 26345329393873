<template>
    <div class="col-xs-10 col-xs-offset-1 topic-section">
        <h3 @click="topic.active = !topic.active">{{ topic.name }}</h3>

        <transition v-if="topic.active">
            <div>
                <div class="row">
                    <div class="col-xs-6 col-xs-offset-1 col-sm-offset-0 col-sm-2">
                        <input v-on:click="selectRequestHandler" class="form-control email-button" type="button" value="Send Request"/>
                    </div>
                    <div class="col-xs-1 col-xs-offset-1">
                        <img title="reload" class="reload-image" :class="{'spin-image' : loading, 'reload-hide' : !loading }" :src="reloadImg"/>
                    </div>
                </div>
                <contact-file v-if="topic.file" :title="topic.file.title" :fileObj="topic.file" @addFile="addFile"></contact-file>

                <contact-input v-for="inp in topic.inputs"
                                :title="inp.title"
                                :type="inp.type"
                                v-model="inp.message"
                                :placeholder="inp.placeholder"
                                :key="inp.title"></contact-input>

                <div v-for="issue in topic.issues" :key="issue.description" class="message-section">
                    <p class="message-header" @click="issue.active = !issue.active">{{ issue.description }}</p>
                    <div class="message" v-if="issue.active">
                        <textarea v-model="issue.message" :placeholder="issue.placeholder ? issue.placeholder : genericPlaceholder"></textarea>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import ContactFile from "./ContactFile.vue";
    import ContactInput from "./ContactInput.vue";
    import Util from "../../util/util";
    import moment from 'moment';
    import reloadImg from "../../../resources/static/img/reload.png";
    import normalizeFileName from "../../util/normalizeFileName";

    export default {
        props: ["topic"],
        data() {
            return {
                reloadImg,
                loading: false,
                genericPlaceholder: "Please explain issue in detail and then click Send Request button above"
            }
        },
        components: {
            ContactFile,
            ContactInput
        },
        computed: {
            name() { return this.$store.state.name; },
            email() { return this.$store.state.email; }
        },
        methods: {
            // topic defines the name of the function to call for the "Send Request" button
            selectRequestHandler() {
                this[this.topic.handleRequest]();
            },
            addFile(file, fileObj) {
                fileObj.file = file;
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            },
            validateInputs() {
                if (!this.name || !this.email) {
                    return "Please enter your name and email address";
                }

                if (!Util.validateEmail(this.email)) {
                    return "Please enter a valid email address";
                }

                let messagesEntered = false;

                //merge values from topic to find if entry was made
                this.topic.hasMessage = this.topic.issues.reduce((acc, curr) => acc + curr.message, "");
                if (this.topic.hasMessage) {

                    messagesEntered = true;
                    //check that required inputs were entered
                    let error = this.topic.inputs.find(inp => inp.required && !inp.message);
                    if (error) {
                        return `Required input not entered for ${this.topic.name}`;
                    }

                    //check that a required file was provided
                    if (this.topic.file !== null && (this.topic.file.required && this.topic.file.file === null)) {
                        return `Required file not provided for ${this.topic.name}`;
                    }
                }
                if (!messagesEntered) {
                    return "Please choose a topic and enter a message";
                }
            },
            sendEmail() {
                let errorMessage = this.validateInputs();
                if (errorMessage) {
                    this.addMessage(errorMessage);
                    return;
                }

                if (this.topic.hasMessage) {
                    this.loading = true;

                    let formData = new FormData();

                    let contactRequest = {
                        name: this.name,
                        email: this.email,
                        topic: this.topic,
                        filename: ""
                    };

                    if (this.topic.file !== null && this.topic.file.file !== null) {
                        formData.append("file" , this.topic.file.file);
                        contactRequest.filename = normalizeFileName(this.topic.file.file.name);
                    }
                    formData.append("contactRequest", JSON.stringify(contactRequest));

                    this.$http.post("/rs/contact/email", formData)
                        .then(response => {
                            this.addMessage("Thanks, email sent!");
                            this.loading = false;
                        })
                        .catch(error => {
                            this.addMessage("Email Send Failed");
                            this.loading = false;
                        });
                }
            },
            requestUploadList() {
                const [startDate, endDate] = this.topic.inputs.map(inp => inp.message);

                if (!Util.validateEmail(this.email)) {
                    this.addMessage("Please enter a valid email address");
                    return;
                }
                if (!startDate || !moment(startDate, "YYYY-MM-DD", true).isValid()) {
                    this.addMessage("Please enter a valid start date.");
                    return;
                }
                if(endDate) {
                    const endDateMoment = moment(endDate, "YYYY-MM-DD", true);
                    if (endDateMoment.isValid() && moment(startDate, "YYYY-MM-DD", true).isAfter(endDateMoment, "day")) {
                        this.addMessage("Please enter a valid end date after the start date");
                        return;
                    }
                }

                this.loading = true;
                this.$http.get("/rs/uploadList", {
                    params: {
                        email: this.email,
                        startDate,
                        endDate
                    }
                }).then(response => {
                    this.addMessage("Email sent");
                    this.loading = false;
                }).catch(error => {
                    this.addMessage("An error occurred sending the email");
                    this.loading = false;
                });
            }
        }
    }
    // TODO Add sendEmail(), move loading to topics
</script>
<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    .email-button {
        @include reiButton;
        margin-bottom: 15px;
        min-width: 125px;
    }

    .topic-section {
        background-color: $mainBgColor;
        margin-bottom: 15px;
        border-radius: 3px;
        @include box-shadow;

        &:hover {
            @include box-shadow-hover;
        }

        ul {
            font-weight: bold;
            font-size: 100%;
            margin-left: 45px;
            font-family: $verandaFontStack;
        }

        p, h3 {

            font-family: $verandaFontStack;
            margin: 30px 0 30px 15px;
        }

        p {
            font-size: 120%;
        }

        h3:hover {
            color: $reiOrange;
            cursor: pointer;
            @include reiTransition;
        }
    }

    .message-section {
        height: 100%;
        margin: 0 auto 10px auto;
        border-radius: 3px;
        text-align: center;
        border: 1px solid gray;
        background-color: $mainOffsetColor;
        @include box-shadow;

        .message textarea {
            width: 95%;
            height: 100px;
            font-size: 140%;
            resize: none;
            border-radius: 3px;
        }

        & > p:hover,
        .topic-section h3:hover {
            color: $reiOrange;
            cursor: pointer;
        }

        & > p {
            font-weight: bold;
            @include reiTransition;
        }
    }

    .reload-image {
        margin-top: 10px;
        margin-right: 10px;
        float: right;
        opacity: 1;
        transition: opacity 0.1s;
    }

    .reload-hide {
        opacity: 0;
    }
</style>
