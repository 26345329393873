<template>
    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">
            <div class="row">
                <div class="col-xs-12">
                    <h3 class="title">Add News</h3>
                    <p class="text-info">Add a News item to the home page. Any existing item will be replaced.</p>
                </div>
            </div>
            <div class="row news-container">
                <div class="col-xs-8 col-sm-8">
                    <input type="text" v-model="news" class="form-control" data-ui="add-news-text"
                            placeholder="Add News item for Home page." maxlength="250"/>
                </div>
                <div class="col-xs-2 col-sm-2">
                    <input type="button" class="form-control admin-btn" value="Publish" data-ui="set-news-btn" @click="setNews"/>
                </div>
                <div class="col-xs-2 col-sm-2">
                    <input type="button" class="form-control admin-btn" value="Clear" data-ui="clear-news-btn" @click="clearNews"/>
                </div>
            </div>
            <div>
                <p data-ui="update-news-text" class="updateText">{{ lastUpdate }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name: "add-news",
    data() {
        return {
            news: "",
            lastUpdate: ""
        }
    },
    methods: {
        setNews() {
            this.news = this.news.trim();
            if (this.news && this.news.length) {
                this.updateNews(this.news, "News item published successfully.");
            } else {
                this.$store.commit("addMessage", "No content to publish.");
            }
        },
        clearNews() {
            this.news = "";
            this.updateNews(this.news, "News item cleared successfully.");
        },
        getNews() {
            this.$http.get("/rs/message?type=NEWS").then(response => {

                const message = response.data;
                if (message && message.content && message.content.length) {
                    this.setLastUpdate(message.lastUpdated, message.userId);
                    this.news = message.content.trim();
                    this.$store.commit("setNews", this.news);
                }
            });
        },
        updateNews(content, successMsg) {
            this.$http.post("/rs/message", {
                type: "NEWS",
                content
            }).then(response => {

                const message = response.data;
                this.setLastUpdate(message.lastUpdated, message.userId);
                this.$store.commit("setNews", content);
                this.$store.commit("addMessage", successMsg);
            }).catch(error => {

                this.$store.commit("addMessage", "Error updating news. " + error);
            });
        },
        setLastUpdate(lastUpdated, userId) {
            const updateDate = moment(lastUpdated).format("MM/DD/YYYY HH:mm");

            this.lastUpdate = `Last update: ${updateDate} by ${userId}`;
        }
    },
    mounted() {
        this.getNews();
    }
}
</script>
<style lang="scss" scoped>

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .admin-panel {
        min-height: 250px;
        height: 100%;
        padding-bottom: 15px;

        .news-container {
            margin-top: 20px;

            .admin-btn {
                margin-top: 0px;
            }
        }

        .updateText {
            font-size: small;
            font-style: italic;
            color: grey;
            padding-left: 5px;
        }
    }

</style>
